import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Injector,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { firstValueFrom, Subscription } from 'rxjs';
import { accumulate, AreaExtra, createEditor, MySelector, ReteEditor } from '../../../rete/defaultEditor';
import { ActivityNode, KennisNode, Schemes } from '../../../rete/nodes';
import { ClassicPreset, NodeEditor } from 'rete';
import { ConnectionData } from '@services/types/ConnectionData';
import { SelectorEntity } from 'rete-area-plugin/_types/extensions/selectable';
import { NodeCategory } from '@services/types/NodeCategory';
import { AreaExtensions, AreaPlugin } from 'rete-area-plugin';
import { ExpectedSchemes as ReteSchemes } from 'rete-auto-arrange-plugin/_types/types';
import { LoadingScreenService } from '@services/UI-elements/loading-screen.service';
import { ConfirmationModalService } from '@services/UI-elements/confirmation-modal.service';
import { AlertService } from '@services/UI-elements/alert-service';
import { BootstrapClass } from '@services/types/BootstrapClass';
import { instant, Logger, sleep, Try, Vector2 } from '@services/utils';
import { FieldType, FieldTypes, FieldValue } from '@services/entities/helpers';
import { NodePosition } from '@services/entities';
import { DataInstanceRepository, EnumTypeRepository, NodePositionRepository } from '@services/repositories';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

export enum FlowchartType {
  MISSION,
  MODULE,
}

@Component({
  selector: 'app-flowchart',
  templateUrl: './flowchart.component.html',
  styleUrls: ['./flowchart.component.scss'],
})
export class FlowchartComponent implements OnInit, AfterViewInit, OnDestroy, OnChanges {
  @ViewChild('rete') nodeEditorView!: ElementRef;
  @ViewChild('pasteModal') pasteModal!: NgbModal;

  @Input({ required: true }) nodes: NodePosition[] = [];
  @Input() flowchartType?: FlowchartType;

  @Output() deleteActivities = new EventEmitter<string[]>();
  @Output() duplicateActivities = new EventEmitter<string[]>();
  @Output() pasteActivities = new EventEmitter<string[]>();
  @Output() addActivity = new EventEmitter<string>();
  @Output() addKennisNode = new EventEmitter<string>();

  @Output() nodeClicked = new EventEmitter<NodePosition>();

  factory?: ReteEditor;
  area?: AreaPlugin<Schemes, AreaExtra>;
  editor?: NodeEditor<Schemes>;
  selector?: MySelector<SelectorEntity>;
  editorIsClearing = false;
  instanceUidToNodeMap: Map<string, ActivityNode | KennisNode> = new Map<string, ActivityNode | KennisNode>();
  activityTypes: string[] = [];
  activityColors: Record<string, string> = {};
  duplicatingNodes = false;
  deletingNodes = false;
  addingNode = false;
  pasteActivityUids = '';
  showLegend: boolean = false;

  protected readonly FlowchartType = FlowchartType;

  private currentActivitySubscription?: Subscription;
  private updateSubscription?: Subscription;
  private nodesSubscription?: Subscription;
  private reteNodeSelectedSubscription?: Subscription;
  private reteNodeUnselectedSubscription?: Subscription;
  private colors = [
    '#FCDF93',
    '#98F5E1',
    '#FF5C5C',
    '#B2E2A2',
    '#FF94E1',
    '#8C7AFF',
    '#FF9178',
    '#B99DF3',
    '#7BC2E5',
    '#FFB649',
    '#CD64FF',
  ];
  private nodePositions: Record<string, Vector2> = {};

  constructor(
    private injector: Injector,
    private loadingScreenService: LoadingScreenService,
    private confirmService: ConfirmationModalService,
    private alertService: AlertService,
    private nodePositionRepository: NodePositionRepository,
    private dataInstanceRepository: DataInstanceRepository,
    private enumTypeRepository: EnumTypeRepository,
    private ngbModal: NgbModal,
  ) {}

  async ngOnChanges(changes: SimpleChanges) {
    if ('nodes' in changes) {
      const activities: NodePosition[] = changes['nodes'].currentValue;

      await this.updateEditor(activities);

      if (activities.length > 1 && activities.every((node) => !node.position || (node.position.x == 0 && node.position.y == 0))) {
        // Nodes are all at 0;0, let's auto-arrange
        await this.arrangeNodes();
      }

      if (changes['nodes'].isFirstChange()) await this.zoomAtAllNodes();
    }
  }

  async ngOnInit() {
    this.showLegend = localStorage.getItem('legend') !== 'false';

    // If the flowchart is for a mission, we have different subscriptions.
    // Because we need to update the connections manually when there are changes in the data.
    switch (this.flowchartType) {
      case FlowchartType.MISSION:
        await this.initializeMissionSubscriptions();
        break;
      case FlowchartType.MODULE:
        break;
      default:
        Logger.warn('Flowchart type: ' + this.flowchartType + ' is not supported yet');
        break;
    }

    this.currentActivitySubscription = this.nodePositionRepository.currentActivity$.subscribe((node) => {
      if (!this.selector || !this.editor || !this.area) return Logger.warn("Selector, editor, or area doesn't exist");
      this.editor.getNodes().forEach((n) => (n.selected = false));

      if (!node) {
        this.selector.unselectAll(true);
        return;
      }

      const nextNode = this.getNodeByInstanceUid(node.dataInstanceUid);
      const area = this.area;
      if (!nextNode) {
        return;
      }

      this.selector.pick({ id: nextNode.id, label: nextNode.label });
      this.selector.add(
        {
          label: 'node',
          id: nextNode.id,
          translate(dx, dy) {
            const view = area.nodeViews.get(nextNode.id);
            const current = view?.position;

            if (current) {
              void view.translate(current.x + dx, current.y + dy);
            }
          },
          unselect() {
            if (nextNode.selected) {
              nextNode.selected = false;
              void area.update('node', nextNode.id);
            }
          },
        },
        accumulate().active(),
      );
      nextNode.selected = true;
      this.area.update('node', nextNode.id);
    });

    await this.loadingScreenService.show(async () => {
      this.activityTypes = (await this.enumTypeRepository.get('Activity')).options;
      for (let i = 0; i < this.activityTypes.length; i++) {
        this.activityColors[this.activityTypes[i]] = this.colors[i % this.colors.length];
      }
    });
  }

  ngOnDestroy() {
    this.updateSubscription?.unsubscribe();
    this.nodesSubscription?.unsubscribe();
    this.currentActivitySubscription?.unsubscribe();
    this.reteNodeSelectedSubscription?.unsubscribe();
    this.reteNodeUnselectedSubscription?.unsubscribe();
  }

  async ngAfterViewInit() {
    const editorElement = this.nodeEditorView.nativeElement;

    if (!editorElement) {
      Logger.warn('Node editor view not found');
      return;
    }

    this.factory = await createEditor(editorElement, this.injector, this.flowchartType === FlowchartType.MODULE);
    this.area = this.factory.area;
    this.editor = this.factory.editor;
    this.selector = this.factory.selector;

    AreaExtensions.snapGrid(this.area, {
      size: 16,
    });

    this.reteNodeSelectedSubscription = this.selector.nodeSelected$.subscribe(async (nodeEntity) => {
      if (!nodeEntity || !this.editor) {
        return Logger.warn(`Node entity or editor not found`);
      }

      const selectedNode = this.editor.getNode(nodeEntity.id);
      if (!selectedNode) {
        return Logger.warn(`Selected node not found`);
      }
      const nodeInstance = this.nodes.find((n) => n.dataInstanceUid == selectedNode.uid);
      Logger.debug(`Flowchart node clicked: '${nodeInstance?.name}'`);

      if (nodeInstance) {
        this.nodeClicked.emit(nodeInstance);
      }
    });

    this.reteNodeUnselectedSubscription = this.selector.nodeUnselected$.subscribe(async (nodeEntity) => {
      if (!nodeEntity || !this.editor) {
        return Logger.warn(`Node entity not found`);
      }
      // We need to wait for the build-in select from Rete to finish so we can unselect the node ourselves
      await sleep(100);
      const selectedNode = this.editor.getNode(nodeEntity.id);
      if (!selectedNode) {
        return Logger.warn(`Selected node not found`);
      }
      selectedNode.selected = false;
    });

    this.editor.addPipe((context) => {
      switch (context.type) {
        case 'connectioncreated': {
          // If the flowchart is for a module, we need to update the connections in the data service
          if (this.flowchartType === FlowchartType.MODULE) {
            this.onAddedConnectionForModuleFlowChart(context.data).then();
          }
          break;
        }
        case 'connectionremoved': {
          // If the flowchart is for a module, we need to update the connections in the data service
          if (this.flowchartType === FlowchartType.MODULE) {
            this.onDeletedConnectionForModuleFlowChart(context.data).then();
          }
          break;
        }
        default:
          break;
      }

      return context;
    });

    this.area.addPipe(async (context) => {
      if (context.type === 'nodedragged') {
        await this.updatePositions();
      }
      return context;
    });
  }

  // Arrange the nodes in the editor
  async arrangeNodes() {
    if (!this.factory) return Logger.warn('Factory not found, cannot arrange nodes');
    await this.factory.layout();
    await this.updatePositions();
  }

  async zoomAtAllNodes() {
    if (!this.factory) return Logger.warn('Factory not found, cannot zoom at all nodes');
    await this.factory.zoomAtAllNodes();
  }

  async deleteSelectedNodes() {
    if (!this.editor) return Logger.warn('Editor not found, cannot delete selected nodes');
    if (!this.selector || this.selector.entities.size === 0) {
      this.alertService.error('No nodes selected');
      return Logger.warn('No nodes selected');
    }

    let confirmed: boolean;
    const nodeNames: string[] = [];

    Array.from(this.selector.entities.values()).forEach((nodeSelector) => {
      const node = this.editor!.getNode(nodeSelector.id);
      nodeNames.push(node ? node.label : '');
    });

    if (this.selector.entities.size === 1) {
      confirmed = await firstValueFrom(
        this.confirmService.confirm(
          'Are you sure you want to delete this activity? This action cannot be undone and will delete all references to this activity.',
        ),
      );
    } else {
      confirmed = await firstValueFrom(
        this.confirmService.confirm(
          `Are you sure you want to delete these activities: ${nodeNames.map((n) => `'${n}'`).join(', ')}? This action cannot be undone and will delete all references to these activities.`,
        ),
      );
    }
    if (!confirmed) return;

    const activitiesToDelete: string[] = [];
    for (const nodeSelector of this.selector!.entities.values()) {
      const node = this.editor!.getNode(nodeSelector.id);
      if (!node) return Logger.warn('Node not found with id: ' + nodeSelector.id);
      activitiesToDelete.push(node.uid);
    }

    this.deletingNodes = true;
    this.deleteActivities.emit(activitiesToDelete);
    this.selector.unselectAll(true);
  }

  async duplicateSelectedNodes() {
    if (!this.editor) return Logger.warn('Editor not found, cannot duplicate selected nodes');
    if (!this.selector || this.selector.entities.size === 0) {
      this.alertService.error('No nodes selected');
      return Logger.warn('No nodes selected');
    }

    const activitiesToDuplicate: string[] = [];
    for (const nodeSelector of this.selector.entities.values()) {
      const node = this.editor.getNode(nodeSelector.id);
      if (!node) return Logger.warn('Node not found with id: ' + nodeSelector.id);
      activitiesToDuplicate.push(node.uid);
    }

    this.duplicatingNodes = true;
    this.duplicateActivities.emit(activitiesToDuplicate);
    this.selector.unselectAll(true);
  }

  copySelectedNodes() {
    if (!this.editor) return Logger.warn('Editor not found, cannot copy selected nodes');
    if (!this.selector || this.selector.entities.size === 0) {
      this.alertService.error('No nodes selected');
      return Logger.warn('No nodes selected');
    }

    const activitiesToCopy: string[] = [];
    for (const nodeSelector of this.selector.entities.values()) {
      const node = this.editor.getNode(nodeSelector.id);
      if (!node) return Logger.warn('Node not found with id: ' + nodeSelector.id);
      activitiesToCopy.push(node.uid);
    }

    navigator.clipboard
      .writeText(activitiesToCopy.join(','))
      .then(() => {
        this.alertService.showAlert(`Successfully copied activities to clipboard`, BootstrapClass.SUCCESS);
      })
      .catch((error) => {
        throw new Error('Failed to copy activities to clipboard: ' + error);
      });
  }

  createActivityNode(activityType: string) {
    this.addActivity.emit(activityType);
  }

  createKennisNode(title: string) {
    this.addKennisNode.emit(title);
  }

  getNodeByInstanceUid(instanceUid: string) {
    if (!this.editor) return Logger.warn('Editor not found, cannot get node');
    return this.editor.getNode(this.instanceUidToNodeMap.get(instanceUid)?.id ?? '');
  }

  async addNode(flowchartNode: NodePosition) {
    if (!this.editor) return Logger.warn('There is no editor defined');
    if (!flowchartNode) return Logger.warn('There is no flowchart node to add');

    // Create the node
    let node: ActivityNode | KennisNode;

    switch (flowchartNode.nodeCategory) {
      case NodeCategory.Activity:
        node = new ActivityNode(
          flowchartNode.name,
          flowchartNode.type,
          flowchartNode.dataInstanceUid,
          this.activityColors[flowchartNode.type],
        );
        break;
      case NodeCategory.Kennis:
        node = new KennisNode(flowchartNode.name, flowchartNode.type, flowchartNode.dataInstanceUid);
        break;
      default:
        return Logger.warn(`Category: ${flowchartNode.nodeCategory} is not supported yet`);
    }

    // Add the node to the editor
    const isNodeAdded = await this.editor.addNode(node);
    if (!isNodeAdded) {
      return Logger.warn('Node not added');
    }

    if (flowchartNode.position) {
      const { x, y, k } = this.area!.area.transform;
      const box = this.area!.container.getBoundingClientRect();
      const halfWidth = box.width / 2;
      const halfHeight = box.height / 2;

      let newNode = false;
      if (flowchartNode.position.x === 0) {
        flowchartNode.position.x = halfWidth - x / k;
        newNode = true;
        this.addingNode = true;
      }

      if (flowchartNode.position.y === 0) {
        flowchartNode.position.y = halfHeight - y / k;
        newNode = true;
        this.addingNode = true;
      }

      if (newNode && this.flowchartType === FlowchartType.MISSION) {
        Logger.info(`Creating position for node: ${flowchartNode.dataInstanceUid}`);

        await this.nodePositionRepository.create(
          {
            dataInstanceUid: flowchartNode.dataInstanceUid,
            positionX: flowchartNode.position.x,
            positionY: flowchartNode.position.y,
          },
          flowchartNode.nodeCategory,
        );
      }
    }

    if (flowchartNode.position && this.area) {
      this.nodePositions[node.id] = new Vector2([flowchartNode.position.x, flowchartNode.position.y]);
      await this.area.translate(node.id, { x: flowchartNode.position.x, y: flowchartNode.position.y });
    }

    this.instanceUidToNodeMap.set(flowchartNode.dataInstanceUid, node);
  }

  async onAddedConnectionForModuleFlowChart(connection: ReteSchemes['Connection']) {
    if (!connection.sourceOutput || !connection.targetInput) {
      return Logger.warn(`No source output for connection: ${connection.id}`);
    }

    const sourceNode = await this.dataInstanceRepository.get(connection.sourceOutput);
    if (!sourceNode) {
      throw new Error('Could not find source node');
    }

    const outgoingConnections = sourceNode.fieldValues['outgoingConnections']!;
    const deserializedConnections = outgoingConnections!.getDeserializedValue(FieldType.LIST, outgoingConnections.value);
    if (!outgoingConnections || !deserializedConnections) {
      throw new Error('No outgoing connections found');
    }

    // Check if the connection is already in the outgoing connections, if not, add it
    if (!deserializedConnections.includes(connection.targetInput)) {
      Logger.info('Adding new connection, and saving it in the data');
      deserializedConnections.push(connection.targetInput);
      await sourceNode.fieldValues['outgoingConnections']!.set(deserializedConnections);
    }
  }

  // Deletes the connection from the data, when the connection is deleted in the editor
  async onDeletedConnectionForModuleFlowChart(connection: ReteSchemes['Connection']) {
    // If the editor is clearing, we don't need to delete the connection in the data
    if (this.editorIsClearing || !connection.sourceOutput || !connection.targetInput) {
      return;
    }

    const sourceNode = await this.dataInstanceRepository.get(connection.sourceOutput);

    const outgoingConnections = sourceNode.fieldValues['outgoingConnections']!;
    const deserializedConnections = outgoingConnections!.getDeserializedValue(FieldType.LIST, outgoingConnections.value);
    if (!outgoingConnections || !deserializedConnections) {
      throw new Error('No outgoing connections found');
    }

    // Check if the connection is in the outgoing connections, if so, remove it
    if (deserializedConnections.includes(connection.targetInput)) {
      Logger.info('Removing connection, and saving it in the data');
      const index = deserializedConnections.indexOf(connection.targetInput);
      deserializedConnections.splice(index, 1);
      await sourceNode.fieldValues['outgoingConnections']!.set(deserializedConnections);
    }
  }

  // Update all connections as currently saved in the data service
  async createConnectionsForMissionFlowChart() {
    if (!this.editor) {
      Logger.warn('Editor not found, cannot update connections');
      return;
    }

    const savedConnections: ConnectionData[] = [];
    const dataInstances = await Promise.all(this.nodes.map((d) => this.dataInstanceRepository.get(d.dataInstanceUid)));

    for (const instance of dataInstances) {
      if (!('activityChange' in instance.structType.fields)) {
        continue;
      }

      const activityChange = instance.fieldValues['activityChange'];
      if (!activityChange || !activityChange.value) {
        continue;
      }

      const attemptConnection = async (type: string, value: string) => {
        if (!(await FieldTypes.isEnumTypeValid(type)) || !(FieldTypes.getReferencedTypeId(type) === 'Activity')) {
          return;
        }

        savedConnections.push({
          sourceUid: await instance.identifier,
          targetUid: value,
        });
      };

      const deserializedValue = activityChange.getDeserializedValue(FieldType.ENUM, activityChange.value) ?? null;
      if (!deserializedValue) {
        Logger.error(`Activity change deserialized value not found in data instance: ${instance.__uid}`);
        continue;
      }

      // We assume the data instance exists, otherwise the whole mission is fucked
      const activityChangeDataInstance = await this.dataInstanceRepository.get(deserializedValue);

      const traversedFields: FieldValue[] = [];
      const traverseFieldValues = async (fieldValues: FieldValue[]) => {
        for (const fieldValue of fieldValues) {
          // To prevent infinite loops
          if (traversedFields.includes(fieldValue)) {
            continue;
          }
          traversedFields.push(fieldValue);
          if (FieldTypes.getReferencedTypeId(fieldValue.field.type) === 'Activity') {
            await attemptConnection(fieldValue.field.type, fieldValue.getDeserializedValue(FieldType.STRING, fieldValue.value) ?? '');
            continue;
          }

          // We don't want to start checking other missions as they certainly don't have any connections with our current node
          if (FieldTypes.getReferencedTypeId(fieldValue.field.type) === 'MissionInfo') {
            continue;
          }

          if (FieldTypes.isListType(fieldValue.field.type)) {
            const list = fieldValue.getDeserializedValue(FieldType.LIST, fieldValue.value) ?? [];

            // There is room for optimization here by first getting the struct type and checking if any field even has
            //  an activity change field value instead of checking all the data instances.
            for (const item of list) {
              const di = await this.dataInstanceRepository.get(item as string);
              await traverseFieldValues(Object.values(di.fieldValues).filter(Boolean) as FieldValue[]);
            }

            continue;
          }

          if ((await FieldTypes.isEnumTypeValid(fieldValue.field.type)) || (await FieldTypes.isStructTypeValid(fieldValue.field.type))) {
            const dataInstanceUid = fieldValue.getDeserializedValue(FieldType.STRING, fieldValue.value);

            if (!dataInstanceUid) {
              continue;
            }

            const dataInstance = await this.dataInstanceRepository.get(dataInstanceUid);
            await traverseFieldValues(Object.values(dataInstance.fieldValues).filter(Boolean) as FieldValue[]);
          }
        }
      };

      await traverseFieldValues(Object.values(activityChangeDataInstance.fieldValues).filter(Boolean) as FieldValue[]);
    }

    const connectionsToCheck = [...savedConnections];
    const connectionsInEditor = this.editor.getConnections();

    for (const connection of connectionsInEditor) {
      // If this connection is in connectionsToCheck, then keep the connection, but delete it from connectionsToCheck
      const index = connectionsToCheck.findIndex(
        (savedConnection) => savedConnection.sourceUid === connection.sourceOutput && savedConnection.targetUid === connection.targetInput,
      );

      if (index !== -1) {
        connectionsToCheck.splice(index, 1);
        continue;
      }

      // Else delete the connection
      if (this.editor) await this.editor.removeConnection(connection.id);
    }

    // For each connection left in connectionsToCheck, add the connection to the editor.
    for (const connection of connectionsToCheck) {
      const sourceNode = this.getNodeByInstanceUid(connection.sourceUid);
      const targetNode = this.getNodeByInstanceUid(connection.targetUid);

      if (!sourceNode || !targetNode) {
        Logger.warn('No source- or targetnode for connection:', connection);
        continue;
      }

      await this.editor.addConnection(new ClassicPreset.Connection(sourceNode, sourceNode.uid, targetNode, targetNode.uid));
    }
  }

  async showPasteModal() {
    const result = await Try(
      async () =>
        await this.ngbModal.open(this.pasteModal, {
          size: 'lg',
        }).result,
    );

    if (result !== null) {
      const success = await this.pasteNodes();
      if (success) this.pasteActivityUids = '';
    }
  }

  formatPastedActivities() {
    return instant(() => {
      this.pasteActivityUids = this.pasteActivityUids
        .split(',')
        .map((uid) => uid.trim())
        .join(',\n');
    });
  }

  setShowLegend(newValue: boolean) {
    this.showLegend = newValue;
    localStorage.setItem('legend', newValue.toString());
  }

  private async pasteNodes(): Promise<boolean> {
    if (!this.editor || !this.selector) {
      Logger.warn('Editor or selector not found, cannot paste nodes');
      return false;
    }

    if (!this.pasteActivityUids) {
      this.alertService.error('No activities to paste');
      Logger.warn('No activities to paste');
      return false;
    }

    const activitiesToPaste = this.pasteActivityUids
      .split(',')
      .map((uid) => uid.trim())
      .filter(Boolean);
    const activityTypes = (await this.enumTypeRepository.get('Activity')).options;

    try {
      await Promise.all(
        activitiesToPaste.map(async (activityUid) => {
          const activity = await Try(() => this.dataInstanceRepository.get(activityUid));

          if (!activity) {
            this.alertService.error('Could not find activity with uid: ' + activityUid);
            throw new Error('Activity not found with uid: ' + activityUid);
          }

          if (!activityTypes.includes(activity.dataType)) {
            this.alertService.error('Cannot paste non-activity dataInstances');
            throw new Error('Cannot paste non-activity dataInstances');
          }
        }),
      );

      this.duplicatingNodes = true;
      this.pasteActivities.emit(activitiesToPaste);
      this.selector.unselectAll(true);
      this.pasteActivityUids = '';
      return true;
    } catch (error) {
      Logger.warn('Failed to paste activities: ' + error);
      return false;
    }
  }

  private async initializeMissionSubscriptions() {
    const activityChangeEnum = await this.enumTypeRepository.get('ActivityChange');

    // When the data is saved, we need to update the editor
    this.updateSubscription = this.dataInstanceRepository.entityUpdated$.subscribe(async (instanceObject) => {
      if (
        instanceObject &&
        this.editor &&
        // TODO: Find a better way to check if we need to update the connections
        (Try(() => !!instanceObject.fieldValues['activityChange']) ||
          activityChangeEnum.options.includes(instanceObject.dataType) ||
          instanceObject.dataType === 'MultipleChoiceOption' ||
          instanceObject.dataType === 'ConditionWithNextActivity' ||
          instanceObject.dataType === 'RequestItemActivityChange')
      ) {
        await this.createConnectionsForMissionFlowChart();
      }
    });
  }

  private async updateEditor(flowchartNodes: NodePosition[]) {
    if (!this.editor) {
      Logger.warn('[updateEditor] Editor not defined');
      return;
    }

    // We need to clear the editor before we can add new nodes
    // We need to set the editorIsClearing flag, because otherwise onRemovedConnectionForMissionFlowChart will be called
    this.editorIsClearing = true;
    const res = await this.editor.clear();
    if (!res) {
      Logger.warn('Editor not cleared');
      return;
    }

    this.instanceUidToNodeMap.clear();
    await Promise.all(flowchartNodes.map(async (node) => this.addNode(node)));

    // If the flowchart is for a module, we need to create the connections in the data service
    switch (this.flowchartType) {
      case FlowchartType.MISSION:
        await this.createConnectionsForMissionFlowChart();
        break;
      case FlowchartType.MODULE:
        await this.createConnectionsForModuleFlowChart(flowchartNodes);
        break;
    }

    if (!this.duplicatingNodes && !this.deletingNodes && !this.addingNode) await this.zoomAtAllNodes();

    this.duplicatingNodes = false;
    this.deletingNodes = false;
    this.addingNode = false;
    this.editorIsClearing = false;
  }

  private async createConnectionsForModuleFlowChart(flowchartNodes: NodePosition[]) {
    if (!this.editor) {
      Logger.warn('[createConnectionsForModuleFlowChart] Editor not defined');
      return;
    }

    // for (const node of flowchartNodes) {
    //   const outgoingConnections = node.fieldValues.find((fieldValue) => fieldValue.field === 'outgoingConnections');
    //   if (!outgoingConnections) throw new Error('No outgoing connections found');
    //
    //   if (typeof outgoingConnections.value === 'string') {
    //     outgoingConnections.value = JSON.parse(outgoingConnections.value);
    //   }
    //
    //   for (const targetUid of outgoingConnections.value as string[]) {
    //     const sourceNode = this.getNodeByInstanceUid(node.dataInstanceUid);
    //     const targetNode = this.getNodeByInstanceUid(targetUid);
    //
    //     if (!sourceNode || !targetNode) {
    //       Logger.warn('No source- or targetnode for connection:', node);
    //       continue;
    //     }
    //
    //     this.editor?.addConnection(new ClassicPreset.Connection(sourceNode, sourceNode.uid, targetNode, targetNode.uid));
    //   }
    // }

    return await Promise.all(
      flowchartNodes.map(async (node) => {
        const dataInstance = await node.getDataInstance();
        const outgoingConnections = dataInstance.fieldValues['outgoingConnections'];
        if (!outgoingConnections) throw new Error('No outgoing connections found');

        const targetUids = outgoingConnections.getDeserializedValue(FieldType.LIST, outgoingConnections.value) as string[];
        for (const targetUid of targetUids) {
          const sourceNode = this.getNodeByInstanceUid(node.dataInstanceUid);
          const targetNode = this.getNodeByInstanceUid(targetUid);

          if (!sourceNode || !targetNode) {
            Logger.warn('No source- or targetnode for connection:', node);
            continue;
          }

          this.editor?.addConnection(new ClassicPreset.Connection(sourceNode, sourceNode.uid, targetNode, targetNode.uid));
        }
      }),
    );
  }

  private async updatePositions() {
    if (!this.editor) {
      Logger.warn('Editor not defined');
      return;
    }

    const editorNodes: Schemes['Node'][] = this.editor.getNodes();

    for (const node of editorNodes) {
      const position = this.area?.nodeViews.get(node.id)?.position;
      if (!position) throw new Error('No node position found');

      if (this.nodePositions[node.id].x === position.x && this.nodePositions[node.id].y === position.y) {
        continue;
      }

      this.nodePositions[node.id].x = position.x;
      this.nodePositions[node.id].y = position.y;

      const savedNode = this.nodes.find((n) => n.dataInstanceUid === node.uid);

      if (this.flowchartType === FlowchartType.MODULE) {
        const kennisNode = await this.dataInstanceRepository.get(node.uid);

        const position = kennisNode.fieldValues['position'];
        if (!position) throw new Error('No position field found');

        // We need to flip the value because Unity uses a different coordinate system and needs these coordinates as well
        await position.set(new Vector2([this.nodePositions[node.id].x, this.nodePositions[node.id].y * -1]));

        if (savedNode) {
          // Because the savedNode is transformed using the `addTransformers` method, we don't need to invert the y here.
          savedNode.position = new Vector2([this.nodePositions[node.id].x, this.nodePositions[node.id].y]);
        }
      }

      try {
        if (!savedNode) {
          // noinspection ExceptionCaughtLocallyJS
          throw new Error('No saved node found');
        }

        savedNode.position = new Vector2([this.nodePositions[node.id].x, this.nodePositions[node.id].y]);
      } catch (err) {
        void err;
        await this.nodePositionRepository.create(
          {
            dataInstanceUid: node.uid,
            positionX: this.nodePositions[node.id].x,
            positionY: this.nodePositions[node.id].y,
          },
          NodeCategory.Activity,
        );
      }
    }
  }
}
