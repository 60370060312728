<div class="resource-title">
  <h1 class="text-white drop-shadow">Media</h1>
  <button (click)="onOpenUploadModal(uploadModal)" class="btn btn-primary" id="create-variable-button">
    <i class="bi bi-plus-circle"></i>
  </button>
</div>
<app-instances-list
  (bulkTagSelectedEmitter)="onBulkTagSelected($event)"
  (deleteInstanceEmitter)="onDeleteMedia($event)"
  (replaceFileEmitter)="onSubmitFileReplace($event)"
  (selectInstanceEmitter)="onSelectMedia(usagesModal, $event)"
  (tagSelectedEmitter)="onTagSelected($event)"
  (updateFileAltEmitter)="onUpdateFileAlt($event)"
  (updateFileNameEmitter)="onUpdateFileName($event)"
  (updateNoteEmitter)="onUpdateNote($event)"
  *ngIf="!loadingFiles && allMedia.length > 0"
  [allInstances]="allMedia"
  [allTags]="allTags"
  [listType]="'Media'"
  [tagsPerInstance]="tagsPerMedia"
/>

<ng-template #usagesModal let-modal>
  <div class="modal-header" id="media-usages-modal-header">
    <h5 class="modal-title">Media usages of {{ selectedMedia?.name }}</h5>
    <button
      (click)="modal.dismiss('Cross click')"
      aria-label="Close"
      class="btn-close"
      id="media-usages-modal-close-button"
      type="button"
    ></button>
  </div>
  <div *ngIf="selectedMedia" class="modal-body">
    <div *ngIf="mediaUsages.length">
      <div *ngFor="let usage of mediaUsages; let j = index">
        <div class="d-flex gap-1 justify-content-between mb-1">
          <div>
            <span *ngFor="let parent of Object.keys(usage); let i = index">
              <b>{{ parent }}</b
              >: {{ usage[parent] }}{{ i < Object.keys(usage).length - 1 ? ' -> ' : '' }}
            </span>
          </div>
          <div [ngStyle]="{ height: '40px', padding: 0 }" class="btn btn-primary align-content-center">
            <a class="text-white p-3" href="{{ mediaUsageURLs[j] }}" target="_blank"><span class="bi bi-box-arrow-up-right"></span></a>
          </div>
        </div>
        <hr />
      </div>
    </div>
    <div *ngIf="!mediaUsages.length" class="text-muted">No usages found.</div>
  </div>
</ng-template>

<ng-template #uploadModal let-modal>
  <div class="modal-header" id="upload-file-modal-header">
    <h5 class="modal-title">Upload media</h5>
    <button
      (click)="modal.dismiss('Cross click')"
      aria-label="Close"
      class="btn-close"
      id="upload-file-modal-close-button"
      type="button"
    ></button>
  </div>
  <div class="modal-body">
    <form (submit)="submitFileUpload(modal)">
      <div class="form-group">
        <input #fileUpload (change)="onFileUploadSelected($event)" class="hidden" id="fileUpload" multiple type="file" />
        <div class="file-upload">
          {{ uploadFileName || 'No file uploaded yet.' }}

          <button
            (click)="fileUpload.click()"
            class="btn btn-primary btn-circle btn-xl"
            id="upload-file-from-local-storage-button"
            type="button"
          >
            <span class="bi bi-paperclip"></span>
          </button>
        </div>
      </div>
      <div *ngIf="uploadFileName" class="form-group">
        <label for="name">Name: </label>
        <input [(ngModel)]="uploadFileName" class="form-control" id="name" name="name" placeholder="Type name..." type="text" />
      </div>
      <div *ngIf="uploadFileName" class="form-group">
        <label for="alt">Description: </label>
        <input [(ngModel)]="uploadFileAlt" class="form-control" id="alt" name="alt" placeholder="Type description..." type="text" />
      </div>
      <button [disabled]="!file" class="btn btn-primary btn-space" type="submit">Submit</button>
    </form>
  </div>
</ng-template>
