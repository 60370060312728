import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AlertService } from '@services/UI-elements/alert-service';
import { getVariableType, VariableType } from '@services/types/VariableType';
import { FieldEditorComponent } from '@services/dynamic-field.service';
import { Logger } from '@services/utils';
import { DataInstance, Variable } from '@services/entities';
import { VariableRepository } from '@services/repositories';
import { NavigationService } from '@services/navigation.service';

@Component({
  selector: 'app-set-variable-field',
  templateUrl: './set-variable-field.component.html',
  styleUrls: ['./set-variable-field.component.scss'],
})
export class SetVariableFieldComponent implements OnInit, FieldEditorComponent<string | number | boolean> {
  @Input({ required: true }) instance!: DataInstance;

  @Output() deleteSetVariable: EventEmitter<void> = new EventEmitter<void>();

  variables: Variable[] = [];
  variableUid = '';
  currentVariable?: Variable;

  value!: string | number | boolean;

  protected readonly String = String;
  protected readonly VariableType = VariableType;

  constructor(
    private alertService: AlertService,
    private variableRepository: VariableRepository,
    private navigationService: NavigationService,
  ) {}

  async ngOnInit() {
    if (!this.instance) throw new Error('Instance not found');

    const allVariables = await this.variableRepository.getAll();
    if (allVariables) this.variables = allVariables;

    if (!this.instance.fieldValues) {
      console.warn('No field values found on instance', this.instance);
      return;
    }

    this.variableUid = this.instance.fieldValues['variable']!.value as string;
    if (!this.variableUid) {
      Logger.warn(`No variable found on instance ${this.instance.__uid}`);
      return;
    }

    this.currentVariable = await this.variableRepository.get(this.variableUid);

    const valueField = this.instance.fieldValues['newValue']!;
    if (this.currentVariable.valueType) {
      this.value = getVariableType(this.currentVariable.valueType).deserialize(valueField.value);
    }
  }

  async onVariableChange() {
    await this.instance.fieldValues['variable']!.set(this.variableUid);
    this.currentVariable = await this.variableRepository.get(this.variableUid);

    this.value = this.currentVariable.valueType ? getVariableType(this.currentVariable.valueType).getDefault() : '';

    await this.instance.fieldValues['newValue']!.set(String(this.value));
  }

  async onAddNewVariable() {
    if (!this.instance) throw new Error('Instance not found');

    const newVariable = await this.variableRepository.create({
      name: 'New Variable',
      variableRef: '_',
      valueType: this.currentVariable?.valueType ?? VariableType.String,
      startValue: '',
      tags: [],
      modified: '',
    });

    await this.instance.fieldValues['variable']!.set(newVariable.variableRef);

    await this.navigationService.navigateToResource(newVariable.variableRef, 'Variable');
  }

  onDelete() {
    this.deleteSetVariable.emit();
  }

  async onViewStruct(variable: Variable | undefined) {
    if (!variable) throw new Error('Variable not found');
    await this.navigationService.navigateToResource(variable.variableRef, 'Variable');
  }

  async update(value?: string) {
    try {
      if (value || value === '') this.value = String(value);
      await this.instance.fieldValues['newValue']!.set(String(this.value));
    } catch (e) {
      Logger.error(e);
      this.alertService.error(`Failed to save changes`);
    }
  }
}
