import { Component, Input, OnInit } from '@angular/core';
import { FieldType, FieldValue, SelectTypeOption } from '@services/entities/helpers';
import { AlertService } from '@services/UI-elements/alert-service';
import { VariableRepository } from '@services/repositories';
import { Logger } from '@services/utils';
import { FieldEditorComponent } from '@services/dynamic-field.service';
import { Variable } from '@services/entities';

@Component({
  selector: 'app-variable-selector-field',
  templateUrl: './variable-selector-field.html',
  styleUrls: ['./variable-selector-field.scss'],
})
export class VariableSelectorFieldComponent implements OnInit, FieldEditorComponent<string> {
  @Input({ required: true }) data!: FieldValue;

  value!: string;
  choices: SelectTypeOption[] = [];

  constructor(
    private alertService: AlertService,
    private variableRepository: VariableRepository,
  ) {}

  async ngOnInit() {
    this.value = this.data.getDeserializedValue(FieldType.VARIABLE_REF, this.data.value);

    this.choices = (await this.variableRepository.getAll())
      .sort((a, b) => a.name.localeCompare(b.name))
      .map(
        (variable: Variable) =>
          new SelectTypeOption({
            optionId: variable.variableRef,
            label: variable.name,
          }),
      );

    this.choices = [
      new SelectTypeOption({
        optionId: '',
        label: 'Select Variable',
      }),
      ...this.choices,
    ];

    if (!this.value) {
      await this.data.set('');
    }
  }

  // Update the data instance when the dropdown is changed
  async update() {
    try {
      await this.data.set(this.value);
    } catch (e) {
      Logger.error(e);
      this.alertService.error('Failed to save changes');
    }
  }
}
