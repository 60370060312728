<button
  (click)="openModal(uploadModal)"
  *ngIf="appearance === 'icon-button'"
  [disabled]="disabled"
  class="btn btn-no-space"
  id="upload-file-button"
  ngbPopover="{{ label }}"
  triggers="hover"
  type="button"
>
  <span [class]="mode === 'edit' ? 'bi bi-pencil' : 'bi bi-upload'"></span>
</button>
<button (click)="openModal(uploadModal)" *ngIf="appearance === 'modal-button'" [disabled]="disabled" mat-menu-item>
  <i [class]="mode === 'edit' ? 'bi bi-pencil' : 'bi bi-upload'"></i>
  {{ label }}
</button>

<!-- 'Upload' modal -->
<ng-template #uploadModal let-modal>
  <div class="modal-header" id="upload-file-modal-header">
    <h5 class="modal-title">{{ label }}</h5>
    <button
      (click)="modal.dismiss('Cross click')"
      aria-label="Close"
      class="btn-close"
      id="upload-file-modal-close-button"
      type="button"
    ></button>
  </div>
  <div class="modal-body">
    <form (submit)="submit(modal)">
      <div class="form-group">
        <input #fileUpload (change)="onFileUploadSelected($event)" [accept]="accept" class="hidden" id="fileUpload" type="file" />

        <div style="display: grid; grid-template-columns: max-content 1fr; gap: 0.5em; align-items: center">
          <div class="file-upload">
            <button
              (click)="fileUpload.click()"
              class="btn btn-primary btn-circle btn-xl"
              id="upload-file-from-local-storage-button"
              type="button"
            >
              <span class="bi bi-paperclip"></span>
            </button>
          </div>

          <app-file-detailed-preview *ngIf="!file && fileToEdit" [file]="fileToEdit" />
          <div *ngIf="file || !fileToEdit" class="pe-2">{{ fileName ?? 'No file uploaded yet.' }}</div>
        </div>
      </div>
      <div *ngIf="file || fileToEdit" class="form-group">
        <label for="name">Name: </label>
        <input
          [(ngModel)]="fileName"
          [maxLength]="120"
          class="form-control"
          id="name-input-field"
          name="name"
          placeholder="Type file name..."
          type="text"
        />
      </div>
      <div *ngIf="file || fileToEdit" class="form-group">
        <label for="alt">Description: </label>
        <input [(ngModel)]="fileAlt" class="form-control" id="alt" name="alt" placeholder="Type description..." type="text" />
      </div>
      <button [disabled]="isUploading || !(file || fileToEdit)" class="btn btn-primary btn-space" type="submit">Submit</button>
    </form>
  </div>
</ng-template>
