import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AlertService } from '@services/UI-elements/alert-service';
import { getVariableType, VariableType } from '@services/types/VariableType';
import { CompareOperator } from '@services/types/CompareOperator';
import { DataInstance, Variable } from '@services/entities';
import { SelectTypeRepository, VariableRepository } from '@services/repositories';
import { Logger, Try } from '@services/utils';
import { FieldValue, SelectTypeOption } from '@services/entities/helpers';
import { FieldEditorComponent } from '@services/dynamic-field.service';
import { NavigationService } from '@services/navigation.service';

@Component({
  selector: 'app-variable-comparison-field',
  templateUrl: './variable-comparison-field.component.html',
  styleUrls: ['./variable-comparison-field.component.scss'],
})
export class VariableComparisonFieldComponent implements OnInit, FieldEditorComponent<string | boolean | number> {
  @Input({ required: true }) instance!: DataInstance;
  @Input() showDelete = false;

  @Output() deleteVariableComparison: EventEmitter<void> = new EventEmitter<void>();

  variables1: Variable[] = [];
  variables2: Variable[] = [];

  currentVariable1?: Variable;
  currentVariable2?: Variable;

  variable1 = '';
  variable2 = '';

  allOperators: SelectTypeOption<CompareOperator>[] = [];
  operators: SelectTypeOption<CompareOperator>[] = [];
  operator = CompareOperator.EqualTo;

  compareWithVariable = false;

  value!: string | boolean | number;

  protected readonly String = String;
  protected readonly VariableType = VariableType;

  constructor(
    private alertService: AlertService,
    private variableRepository: VariableRepository,
    private selectTypeRepository: SelectTypeRepository,
    private navigationService: NavigationService,
  ) {}

  async ngOnInit() {
    if (!this.instance) throw new Error('No instance provided');

    const [variables, operators] = await Promise.all([
      this.variableRepository.getAll(),
      this.selectTypeRepository.get<CompareOperator>('VariableOperator'),
    ]);

    this.variables1 = variables;
    this.allOperators = operators.options;
    this.operator = (this.allOperators.find((operator) => operator.optionId === this.operator) ?? this.allOperators[0]).optionId;

    if (this.instance.fieldValues) {
      for (const field of Object.values(this.instance.fieldValues).filter(Boolean) as FieldValue[]) {
        // The value field cannot be set in here as it needs the variable1 field for its type
        switch (field.field.fieldId) {
          case 'variable1': {
            this.variable1 = field.value;
            this.currentVariable1 = this.variable1 ? await this.variableRepository.get(this.variable1) : undefined;
            break;
          }

          case 'variable2': {
            this.variable2 = field.value;
            break;
          }

          case 'operator': {
            if (!field.value) {
              this.operator = CompareOperator.EqualTo;
              break;
            }

            switch (field.value) {
              case CompareOperator.EqualTo:
              case CompareOperator.NotEqualTo:
              case CompareOperator.SmallerThan:
              case CompareOperator.GreaterThan:
              case CompareOperator.SmallerOrEqualTo:
              case CompareOperator.GreaterOrEqualTo: {
                this.operator = field.value;
                break;
              }
              default:
                this.operator =
                  Try(() => Object.values(CompareOperator).find((operator) => operator === field.value)) ?? CompareOperator.EqualTo;
                break;
            }
            break;
          }
        }
      }

      if (this.variable1) {
        if (!this.currentVariable1) {
          throw new Error('Variable not found');
        }

        if (this.currentVariable1.valueType) {
          const variableType = getVariableType(this.currentVariable1.valueType);
          this.operators = this.allOperators.filter((operator) => variableType.getValidOperators().includes(operator.optionId));
          const valueField = this.instance.fieldValues['value']!;
          this.value = variableType.deserialize(valueField.value);
        }

        this.variables2 = variables.filter((variable) => variable.valueType === this.currentVariable1?.valueType);
      }

      // For backwards compatibility
      if (this.variable2 === 'CompareVariableWithValue') {
        this.variable2 = '';
        await this.instance.fieldValues['variable2']!.set('');
      }

      if (this.variable2) {
        this.currentVariable2 = await this.variableRepository.get(this.variable2);
        this.compareWithVariable = true;
        if (!this.currentVariable2) throw new Error('Variable not found');
      }
    }
  }

  async onVariableChange(fieldName: 'variable1' | 'operator' | 'variable2') {
    if (!this.instance) {
      throw new Error('Instance not found');
    }

    const field = this.instance.fieldValues[fieldName];
    if (!field) {
      throw new Error('Field not found');
    }

    switch (field.field.fieldId) {
      case 'variable1': {
        const newVariable = await this.variableRepository.get(this.variable1);
        await field.set(this.variable1);

        if (newVariable.valueType !== this.currentVariable1?.valueType) {
          if (newVariable.valueType) {
            const variableType = getVariableType(newVariable.valueType);
            this.operators = this.allOperators.filter((operator) => variableType.getValidOperators().includes(operator.optionId));
          }

          this.variables2 = this.variables1.filter((variable) => variable.valueType === newVariable.valueType);

          if (this.compareWithVariable) {
            this.variable2 = '';
            await this.instance.fieldValues['variable2']!.set('');
          } else {
            await this.setDefault(newVariable.valueType!);
          }

          this.operator = CompareOperator.EqualTo;
          await this.instance.fieldValues['operator']!.set(this.operator);
        }

        this.currentVariable1 = newVariable;
        break;
      }

      case 'variable2': {
        await field.set(this.variable2);
        this.currentVariable2 = await this.variableRepository.get(this.variable2);
        break;
      }

      case 'operator': {
        await field.set(this.operator satisfies CompareOperator);
        break;
      }

      default: {
        throw new Error(`Unknown field '${field}'`);
      }
    }
  }

  async onSwitchCompareWithVariable() {
    if (!this.instance) throw new Error('Instance not found');

    if (this.compareWithVariable) {
      this.variable2 = '';
      this.compareWithVariable = false;
      await this.instance.fieldValues['variable2']!.set('');
    } else {
      this.value = '';
      this.compareWithVariable = true;
      await this.instance.fieldValues['value']!.set(this.value satisfies string);
    }
  }

  async onViewStruct(variable: Variable | undefined) {
    if (!variable) throw new Error('Variable not found');
    await this.navigationService.navigateToResource(variable.variableRef, 'Variable');
  }

  async onAddNewVariable(fieldValueKey: 'variable1' | 'variable2') {
    if (!this.instance) throw new Error('Instance not found');

    const newVariable = await this.variableRepository.create({
      name: 'New Variable',
      variableRef: '_',
      valueType: this.currentVariable1?.valueType ?? VariableType.String,
      startValue: '',
      tags: [],
      modified: '',
    });

    await this.instance.fieldValues[fieldValueKey]!.set(newVariable.variableRef);

    await this.navigationService.navigateToResource(newVariable.variableRef, 'Variable');
  }

  onDelete() {
    this.deleteVariableComparison.emit();
  }

  async update(value?: string) {
    try {
      if (value || value === '') this.value = String(value);

      await Promise.all([
        this.instance.fieldValues['value']!.set(String(this.value) satisfies string),
        this.instance.fieldValues['operator']!.set(this.operator satisfies CompareOperator),
        this.instance.fieldValues['variable1']!.set(this.variable1),
        this.instance.fieldValues['variable2']!.set(this.variable2),
      ]);
    } catch (e) {
      Logger.error(e);
      this.alertService.error(`Failed to save changes`);
    }
  }

  private async setDefault(variableType: VariableType) {
    const fieldValue = this.instance.fieldValues['value']!;

    const set = (value: string | number | boolean) => {
      this.value = value;
      return fieldValue.set(String(this.value) satisfies string);
    };

    if (fieldValue.field.defaultValue) {
      return await set(fieldValue.field.defaultValue);
    }

    await set(getVariableType(variableType).getDefault());
  }
}
