import { Tag } from '@services/entities';
import { Serializable } from '@services/types/Serializable';
import { GeneratedFileMeta } from '@services/types/generated';

export interface NewFileMeta {
  uid: string;
  name: string;
  alt: string;
  fileSize: number;
  fileType: string;
  fileUrl: string;
  modified: string;
  url: string;
  width?: number;
  height?: number;
  tags: Tag[];
  note?: string;
}

export class FileMeta implements Serializable<GeneratedFileMeta> {
  public readonly uid: string;
  public name: string;
  public alt: string;
  public fileSize: number;
  public fileType: string;
  public fileUrl: string;
  public readonly modified: string;
  public url: string;
  public width?: number;
  public height?: number;
  public tags: Tag[];
  public note?: string;

  constructor({ uid, name, alt, fileSize, fileType, fileUrl, modified, url, tags, note, width, height }: NewFileMeta) {
    this.uid = uid;
    this.name = name;
    this.alt = alt;
    this.fileSize = fileSize;
    this.fileType = fileType;
    this.fileUrl = fileUrl;
    this.modified = modified;
    this.url = url;
    this.tags = tags;
    this.note = note;
    this.width = width;
    this.height = height;
  }

  public static async deserialize(data: GeneratedFileMeta): Promise<FileMeta> {
    return new FileMeta({
      uid: data.uid,
      name: data.name,
      alt: data.alt,
      fileSize: data.fileSize,
      fileType: data.fileType,
      fileUrl: data.fileUrl,
      modified: data.modified,
      url: data.url,
      tags: await Promise.all(data.tags.map((tag) => Tag.deserialize(tag))),
      note: data.note,
      width: data.width,
      height: data.height,
    });
  }

  public async serialize(): Promise<Readonly<GeneratedFileMeta>> {
    return Object.freeze({
      uid: this.uid,
      name: this.name,
      alt: this.alt,
      fileSize: this.fileSize,
      fileType: this.fileType,
      fileUrl: this.fileUrl,
      modified: this.modified,
      url: this.url,
      tags: await Promise.all(this.tags.map((tag) => tag.serialize())),
      note: this.note,
      width: this.width,
      height: this.height,
    });
  }
}
