import Konva from 'konva';
import { FieldValueForCanvasEditor } from '@services/types/FieldValueForCanvasEditor';
import { Try, Vector2 } from '@services/utils';
import { DataInstanceRepository } from '@services/repositories';
import { filter, map, mergeMap, startWith, Subscription } from 'rxjs';
import { DataInstance } from '@services/entities';

export type DataInstanceFieldData<T> = {
  dataInstanceUid: string;
  fieldValue: FieldValueForCanvasEditor<T>;
};

export enum ShapeType {
  RECTANGLE = 'Rectangle',
  CIRCLE = 'Circle',
  PIN = 'Pin',
}

type Rectangle = {
  type: ShapeType.RECTANGLE;
  size: DataInstanceFieldData<Vector2>;
  media: DataInstanceFieldData<string>;
};

type Circle = {
  type: ShapeType.CIRCLE;
  radius: DataInstanceFieldData<number>;
  media: DataInstanceFieldData<string>;
};

type Pin = {
  type: ShapeType.PIN;
  position: DataInstanceFieldData<Vector2>;
  panelPosition: DataInstanceFieldData<Vector2>;
};

export type VisualTarget = (Rectangle | Circle | Pin) & {
  instance: DataInstance;
  visualElementInstance: DataInstance;
  position: DataInstanceFieldData<Vector2>;
  pivot?: DataInstanceFieldData<Vector2>; // NOTE: pivot is optional for backwards compatibility
  konvaShape?: Konva.Shape[];
  konvaImage?: Konva.Image;
  konvaPivot?: Konva.Circle;
  konvaLabel?: VisualTargetKonvaLabel;
  name: string;
  isCorrect?: boolean;
  hideBorder: boolean;
  visible: boolean;
  locked: boolean;
  targetType: TargetType;
  randomUid: string;
};

export class PivotKonvaRect {
  public constructor(
    public readonly origin: Vector2,
    public readonly size: Vector2,
  ) {}

  public get center() {
    return new Vector2({
      x: this.origin.x + this.size.x / 2,
      y: this.origin.y + this.size.y / 2,
    });
  }

  public get bottomLeft() {
    return this.origin;
  }

  public get bottomCenter() {
    return new Vector2({
      x: this.origin.x + this.size.x / 2,
      y: this.origin.y,
    });
  }

  public get bottomRight() {
    return new Vector2({
      x: this.origin.x + this.size.x,
      y: this.origin.y,
    });
  }

  public get leftCenter() {
    return new Vector2({
      x: this.origin.x,
      y: this.origin.y + this.size.y / 2,
    });
  }

  public get topLeft() {
    return new Vector2({
      x: this.origin.x,
      y: this.origin.y + this.size.y,
    });
  }

  public get topCenter() {
    return new Vector2({
      x: this.origin.x + this.size.x / 2,
      y: this.origin.y + this.size.y,
    });
  }

  public get topRight() {
    return new Vector2({
      x: this.origin.x + this.size.x,
      y: this.origin.y + this.size.y,
    });
  }

  public get rightCenter() {
    return new Vector2({
      x: this.origin.x + this.size.x,
      y: this.origin.y + this.size.y / 2,
    });
  }

  public get points() {
    return [
      this.bottomCenter,
      this.bottomLeft,
      this.bottomRight,
      this.center,
      this.leftCenter,
      this.rightCenter,
      this.topCenter,
      this.topLeft,
      this.topRight,
    ];
  }

  public static fromShape(shape: Konva.Shape): PivotKonvaRect | undefined {
    if (shape instanceof Konva.Rect) {
      return new PivotKonvaRect(
        new Vector2({
          x: shape.x(),
          y: shape.y(),
        }),
        new Vector2({ x: shape.width(), y: shape.height() }),
      );
    }

    if (shape instanceof Konva.Circle) {
      return new PivotKonvaRect(
        new Vector2({ x: shape.x() - shape.radius(), y: shape.y() - shape.radius() }),
        new Vector2({ x: 2 * shape.radius(), y: 2 * shape.radius() }),
      );
    }

    return undefined;
  }

  public static fromTarget(target: VisualTarget, canvasSize: Vector2): PivotKonvaRect {
    switch (target.type) {
      case ShapeType.RECTANGLE: {
        const position = target.position.fieldValue.value;
        const size = target.size.fieldValue.value;
        return new PivotKonvaRect(
          new Vector2({
            x: position.x * canvasSize.x,
            y: position.y * canvasSize.y,
          }),
          new Vector2({ x: size.x * canvasSize.x, y: size.y * canvasSize.y }),
        );
      }

      case ShapeType.CIRCLE: {
        const position = target.position.fieldValue.value;
        const radius = target.radius.fieldValue.value;
        return new PivotKonvaRect(
          new Vector2({ x: (position.x - radius) * canvasSize.x, y: (position.y - radius) * canvasSize.y }),
          new Vector2({ x: 2 * radius * canvasSize.x, y: 2 * radius * canvasSize.y }),
        );
      }

      default:
        throw new Error(`Target type '${target.type}' is not supported`);
    }
  }
}

export class VisualTargetKonvaLabel {
  private readonly instance: DataInstance;
  private readonly konvaText: Konva.Text;
  private readonly parent: {
    position: Vector2;
    size: Vector2;
    shape: ShapeType;
  };
  private anchor: string;

  private constructor(visualElementInstance: DataInstance, position: Vector2, size: Vector2, parentShape: ShapeType) {
    this.instance = visualElementInstance;
    this.parent = {
      position,
      size,
      shape: parentShape,
    };
    this.anchor = visualElementInstance.fieldValues['labelPosition']!.value;
    this.konvaText = new Konva.Text({
      fontFamily: 'Calibri',
      padding: 5,
      textFill: 'white',
      fill: 'black',
      alpha: 0.75,
      visible: true,
      listening: false,
      sceneFunc: function (context, shape) {
        // draw containing rectangle for text
        context.fillStyle = '#cccccc';
        const text = shape as Konva.Text;
        context.fillRect(0, 0, text.width(), text.height());

        // draw text
        (shape as Konva.Text)._sceneFunc(context);
      },
    });
  }

  public static create(
    target: VisualTarget,
    position: Vector2,
    size: Vector2,
    ctx: {
      subscriptions: Subscription[];
      dataInstanceRepository: DataInstanceRepository;
      layer: Konva.Layer;
    },
  ) {
    const label = new VisualTargetKonvaLabel(target.visualElementInstance, position, size, target.type);
    label.init(ctx);
    ctx.layer.add(label.konvaText);
    return label;
  }

  public updateText(text: string) {
    this.konvaText.text(text);
    this.konvaText.visible(text.length > 0);

    this.update();
  }

  public updateStyle(style: string) {
    const fontSizes = {
      Header: 30,
      Subheader: 20,
      Small: 14,
      Normal: 16,
    } as Record<string, number>;
    this.konvaText.fontSize(fontSizes[style] ?? 12);

    this.update();
  }

  public updateAnchor(anchor: string) {
    this.anchor = anchor;

    this.update();
  }

  public updateFrom(shape: Konva.Shape) {
    this.parent.size.x = shape.width();
    this.parent.size.y = shape.height();
    this.parent.position.x = shape.x();
    this.parent.position.y = shape.y();

    this.update();
  }

  public destroy() {
    this.konvaText.destroy();
  }

  private init(ctx: { subscriptions: Subscription[]; dataInstanceRepository: DataInstanceRepository; layer: Konva.Layer }) {
    ctx.subscriptions.push(
      ctx.dataInstanceRepository.entityUpdated$
        .pipe(
          startWith(this.instance),
          mergeMap(async (instance) => ({
            instance,
            isValid: !!instance && (await instance.identifier) === (await this.instance.identifier),
          })),
          filter(({ isValid }) => isValid),
          map(({ instance }) => instance as DataInstance),
          map((instance) => {
            const labelField = Try(() => instance.fieldValues['label']);
            const labelPositionField = Try(() => instance.fieldValues['labelPosition']);
            const labelStyleField = Try(() => instance.fieldValues['labelStyle']);
            return {
              content: labelField?.value,
              position: labelPositionField?.value,
              style: labelStyleField?.value,
            };
          }),
        )
        .subscribe(({ content, position, style }) => {
          this.updateText(content ?? '');
          this.updateAnchor(position ?? 'In');
          this.updateStyle(style ?? 'Normal');
        }),
    );
  }

  private update() {
    this.konvaText.width(undefined as unknown as number); // NOTE: the type definition of the library is wrong
    if (this.konvaText.width() > 2 * this.parent.size.x) {
      this.konvaText.width(2 * this.parent.size.x);
    }

    if (this.anchor === 'In') {
      this.konvaText.width(this.parent.size.x);
      this.konvaText.align('center');
      this.konvaText.verticalAlign('middle');
    } else {
      this.konvaText.align(undefined as unknown as string); // NOTE: the type definition of the library is wrong
      this.konvaText.verticalAlign(undefined as unknown as string); // NOTE: the type definition of the library is wrong
    }

    this.konvaText.x(this.parent.position.x);
    this.konvaText.y(this.parent.position.y);
    switch (this.anchor) {
      case 'Above': {
        this.konvaText.x(this.parent.position.x + this.parent.size.x / 2 - this.konvaText.width() / 2);
        this.konvaText.y(this.parent.position.y - this.konvaText.height());
        break;
      }
      case 'Below': {
        this.konvaText.x(this.parent.position.x + this.parent.size.x / 2 - this.konvaText.width() / 2);
        this.konvaText.y(this.parent.position.y + this.parent.size.y);
        break;
      }
      case 'Left': {
        this.konvaText.x(this.parent.position.x - this.konvaText.width());
        this.konvaText.y(this.parent.position.y + this.parent.size.y / 2 - this.konvaText.height() / 2);
        break;
      }
      case 'Right': {
        this.konvaText.x(this.parent.position.x + this.parent.size.x);
        this.konvaText.y(this.parent.position.y + this.parent.size.y / 2 - this.konvaText.height() / 2);
        break;
      }
      case 'In':
      default: {
        this.konvaText.x(this.parent.position.x + this.parent.size.x / 2 - this.konvaText.width() / 2);
        this.konvaText.y(this.parent.position.y + this.parent.size.y / 2 - this.konvaText.height() / 2);
        break;
      }
    }
    if (this.parent.shape === ShapeType.CIRCLE) {
      this.konvaText.x(this.konvaText.x() - this.parent.size.x / 2);
      this.konvaText.y(this.konvaText.y() - this.parent.size.y / 2);
    }
  }
}

export type ContextMenuOptions = Record<
  string,
  {
    label: string;
    action: (x: number, y: number) => void;
  }[]
>;

export enum Orientation {
  Vertical,
  Horizontal,
}

export enum TargetType {
  CLICK_TARGET = 'ClickTarget',
  VISUAL_ELEMENT = 'VisualElement',
  DROP_TARGET = 'DropTarget',
  DRAGGABLE = 'Draggable',
  MAP_PIN = 'MapPin',
}

export type SnappingEdge = {
  guide: number;
  offset: number;
  snap: 'start' | 'center' | 'end';
};

export type LineStops = {
  lineGuide: number;
  offset: number;
  snap: 'start' | 'center' | 'end';
  diff: number;
};

export type LineGuide = {
  lineGuide: number;
  offset: number;
  snap: 'start' | 'center' | 'end';
  orientation: 'V' | 'H';
};
