import { Component, HostListener, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { firstValueFrom, Subscription } from 'rxjs';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { AlertService } from '@services/UI-elements/alert-service';
import { BootstrapClass } from '@services/types/BootstrapClass';
import { Title } from '@angular/platform-browser';
import { HttpErrorResponse } from '@angular/common/http';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { UnsavedChangesCheck } from '@guards/saved-changes-checker.guard';
import { LoadingScreenService } from '@services/UI-elements/loading-screen.service';
import { EnumTypeRepository, SelectTypeRepository, StructTypeRepository, TagRepository } from '@services/repositories';
import { EnumType, SelectType, StructType, Tag } from '@services/entities';
import { Field, FieldTypes, SelectTypeOption } from '@services/entities/helpers';
import { environment } from '../../../../environments/environment';
import { GeneratedField, GeneratedFieldEditor, GeneratedFieldValidation, GeneratedTag } from '@services/types/generated';
import { Vector2 } from '@services/utils';
import { ConfirmationModalService } from '@services/UI-elements/confirmation-modal.service';
import { FieldEditorType } from '@services/types/FieldEditorType';
import { FieldValidation } from '@services/entities/helpers/FieldValidation';
import { SchemaTableField } from '@services/types/SchemaTableField';
import { cloneDeep } from 'lodash';
import { NavigationService } from '@services/navigation.service';

type EditableTable = {
  type: 'StructType' | 'EnumType' | 'SelectType';
  name: string;
  typeId: string;
  description: string;
  isResource: boolean;
  fields: SchemaTableField[];
  options: string[];
  selectTypeOptions: SelectTypeOption[];
};

@Component({
  selector: 'app-schema-table',
  templateUrl: './schema-table.component.html',
  styleUrls: ['./schema-table.component.scss'],
})
export class SchemaTableComponent implements OnInit, OnDestroy, UnsavedChangesCheck {
  table?: StructType | SelectType | EnumType; // The table Object
  editableTable: EditableTable;
  tableHistory: EditableTable[] = [];
  tablePointer = 0; // A pointer that indicates the current version of the table
  savePointer = 0; // A pointer that indicates the pointer value of the last save

  schemaTypes: string[] = []; // A list of all possible types in the schema
  filteredSchemaTypes: string[] = []; // A filtered list of all possible types
  structTypes: Record<string, StructType> = {}; // A list of all StructTypes in the schema
  filteredStructsTypes: string[] = []; // A filtered list of all StructTypes
  enumTypes: Record<string, EnumType> = {};
  selectTypes: Record<string, SelectType> = {};

  selectedParentField: Field | undefined; // The selected parent-field in a migration modal
  selectedSubField: Field | undefined; // The selected sub-field in a migration modal
  fieldsFromSubStruct: Field[] = [];

  fieldEditorTypes: { name: string; value: string }[] = [
    { name: 'Default', value: FieldEditorType.Default },
    { name: 'Inline', value: FieldEditorType.Inline },
    { name: 'SeamlessInline', value: FieldEditorType.SeamlessInline },
    { name: 'Hidden', value: FieldEditorType.Hidden },
    { name: 'Chat', value: FieldEditorType.Chat },
    { name: 'CanvasEditor', value: FieldEditorType.CanvasEditor },
    { name: 'ChatEditor', value: FieldEditorType.ChatEditor },
  ];
  textEditorTypeOptions: Array<keyof typeof GeneratedFieldEditor.TextEditorTypeEnum> = Object.keys(
    GeneratedFieldEditor.TextEditorTypeEnum,
  ) as Array<keyof typeof GeneratedFieldEditor.TextEditorTypeEnum>;

  allTags: Tag[] = [];
  openAccordionIndex: number | null = null;

  // We need a field that can serve as a way to have no field chosen in the showIf dropdown, hence the dummy field
  fieldsForShowIf: { fieldId: string; name: string }[] = [{ fieldId: 'Select a field', name: 'Select a field' }];

  protected readonly Object = Object;
  protected readonly FieldTypes = FieldTypes;
  protected readonly GeneratedTag = GeneratedTag;
  protected readonly GeneratedFieldEditor = GeneratedFieldEditor;
  private routeParamsSub?: Subscription; // Subscription to route parameters
  private tagSubscription?: Subscription;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private alertService: AlertService,
    private titleService: Title,
    private modalService: NgbModal,
    private loadingScreenService: LoadingScreenService,
    private structTypeRepository: StructTypeRepository,
    private enumTypeRepository: EnumTypeRepository,
    private selectTypeRepository: SelectTypeRepository,
    private tagRepository: TagRepository,
    private confirmationModalService: ConfirmationModalService,
    private navigationService: NavigationService,
  ) {
    this.editableTable = {
      type: 'StructType',
      name: '',
      typeId: '',
      description: '',
      isResource: false,
      fields: [],
      options: [],
      selectTypeOptions: [],
    };
  }

  @HostListener('window:beforeunload')
  hasNoUnsavedChanges() {
    return !this.hasUnsavedChanges();
  }

  /**
   * Initializes the component by setting up subscriptions to fetch schema data and to handle route parameters.
   */
  async ngOnInit() {
    await this.loadingScreenService.show(async () => {
      await this.loadSchemaData();
      this.handleRouteParams();
    });
  }

  ngOnDestroy() {
    this.routeParamsSub?.unsubscribe();
    this.tagSubscription?.unsubscribe();
  }

  /**
   * Loads the schema to map all possible schema types
   */
  async loadSchemaData() {
    const structs = (await this.structTypeRepository.getAll()).flatMap((structType) => [
      `StructRef<${structType.typeId}>`,
      `Struct<${structType.typeId}>`,
    ]);
    (await this.enumTypeRepository.getAll()).forEach((enumType) => {
      this.enumTypes[enumType.typeId] = enumType;
    });
    const enums = Object.values(this.enumTypes).flatMap((enumType) => [`EnumRef<${enumType.typeId}>`, `Enum<${enumType.typeId}>`]);
    (await this.selectTypeRepository.getAll()).forEach((selectType) => {
      this.selectTypes[selectType.typeId] = selectType;
    });
    const selects = Object.values(this.selectTypes).flatMap((selectType) => `Select<${selectType.typeId}>`);
    const lists = [...structs, ...enums, ...selects].map((table) => `List<${table}>`);
    this.structTypes = {};
    (await this.structTypeRepository.getAll()).forEach((structType) => {
      this.structTypes[structType.typeId] = structType;
    });
    this.schemaTypes.push(
      'string',
      'int',
      'bool',
      'float',
      'Vector2',
      'Vector3',
      'Color',
      'Icon',
      'ImageRef',
      'AudioRef',
      'VideoRef',
      'VariableRef',
      'FileRef',
      ...structs,
      ...enums,
      ...selects,
      ...lists,
    );
    this.allTags = await this.tagRepository.getAll();
    this.tagSubscription = this.tagRepository.cache$.subscribe((tags) => {
      this.allTags = tags.filter((tag) => tag.scope === GeneratedTag.ScopeEnum.Model).sort((a, b) => a.name.localeCompare(b.name));
    });
  }

  /**
   * Initialize the table by fetching table data matching the route parameters
   */
  handleRouteParams() {
    this.routeParamsSub = this.activatedRoute.params.subscribe(async (params) => {
      const typeId = params[this.navigationService.queryParamKeys.DataModelTypeId];
      const type = params[this.navigationService.queryParamKeys.DataModelType];

      if (typeId) {
        try {
          switch (type) {
            case 'StructType': {
              this.table = await this.structTypeRepository.get(typeId);
              for (const field of Object.values(this.table.fields)) {
                if (field.type === 'bool' || (await FieldTypes.isSelectTypeValid(field.type))) {
                  this.fieldsForShowIf.push({ fieldId: field.fieldId, name: field.name });
                }
              }
              this.editableTable = {
                type: 'StructType',
                name: this.table.name,
                typeId: this.table.typeId,
                description: this.table.description ?? '',
                isResource: this.table.isResource,
                fields: await Promise.all(
                  Object.values(this.table.fields).map(
                    async (field) => await this.createEditableField(cloneDeep(await field.serialize()), field.tags, false),
                  ),
                ),
                options: [],
                selectTypeOptions: [],
              };
              this.editableTable.fields.forEach((field) => {
                if (
                  field.generatedField.fieldEditor &&
                  (!field.generatedField.fieldEditor.showIf || field.generatedField.fieldEditor.showIf === '')
                ) {
                  field.generatedField.fieldEditor.showIf = 'Select a field';
                }
              });
              this.initEmptyFieldEditors();
              this.orderFields();
              break;
            }
            case 'EnumType': {
              this.table = await this.enumTypeRepository.get(typeId);
              this.editableTable = {
                type: 'EnumType',
                name: this.table.name,
                typeId: this.table.typeId,
                description: this.table.description ?? '',
                isResource: this.table.isResource ?? false,
                fields: [],
                options: this.table.options.slice(),
                selectTypeOptions: [],
              };
              break;
            }
            case 'SelectType': {
              this.table = await this.selectTypeRepository.get(typeId);
              this.editableTable = {
                type: 'SelectType',
                name: this.table.name,
                typeId: this.table.typeId,
                description: this.table.description ?? '',
                isResource: false,
                fields: [],
                options: [],
                selectTypeOptions: this.table.options.slice(),
              };
              break;
            }
            default:
              console.warn(`Unknown type: ${type}`);
          }
        } catch (error) {
          this.handleError(error);
          return;
        }
        this.titleService.setTitle(
          `${environment.defaultGame.charAt(0).toUpperCase() + environment.defaultGame.slice(1)} - ${this.table?.name} - CAS`,
        );

        this.tableHistory = [];
        this.adjustDefaultValues();
        this.saveCurrentState();
      }
    });
  }

  /**
   * Delete the table based on its type.
   */
  async deleteTable() {
    if (!this.table) return;

    // Multiple confirmation warnings
    if (!(await firstValueFrom(this.confirmationModalService.confirm(`Are you sure you want to delete ${this.table.typeId}?`)))) return;

    if (
      !(await firstValueFrom(
        this.confirmationModalService.confirm(
          `Deleting ${this.table.typeId} also means deleting all instances (and sub objects) of ${this.table.typeId}. Are you still sure? Maybe you want to make a backup first?`,
        ),
      ))
    )
      return;

    if (
      !(await firstValueFrom(
        this.confirmationModalService.confirm(`WARNING!\n\nYOU ARE ABOUT TO DELETE ALL INSTANCES OF ${this.table.typeId}`),
      ))
    )
      return;

    // Delete the table and user gets referred back to the schema-list
    try {
      if (this.table instanceof StructType) {
        await this.structTypeRepository.delete(this.table);
      } else if (this.table instanceof EnumType) {
        await this.enumTypeRepository.delete(this.table);
      } else {
        await this.selectTypeRepository.delete(this.table);
      }
      this.router.navigate(this.navigationService.getUrlOfDataModelType('', '')).then();
    } catch (error) {
      if (error instanceof HttpErrorResponse && error.error && error.error.status === 409 && !error.error.message.includes('unique')) {
        if (
          !(await firstValueFrom(
            this.confirmationModalService.confirm(
              `${error.error.error}: ${error.error.message}\n\nDo you want to use the force parameter and delete all values of ${this.table.typeId}?`,
            ),
          ))
        ) {
          return;
        }

        try {
          if (this.table instanceof StructType) {
            await this.structTypeRepository.delete(this.table, true);
          } else if (this.table instanceof EnumType) {
            await this.enumTypeRepository.delete(this.table, true);
          } else {
            await this.selectTypeRepository.delete(this.table, true);
          }
          this.router.navigate(this.navigationService.getUrlOfDataModelType('', '')).then();
        } catch (e) {
          this.handleError(e);
          return;
        }
      } else {
        this.handleError(error);
      }
    }
  }

  /**
   * Update the table based on its type.
   */
  async updateTable() {
    if (!this.table) {
      return;
    }
    // Adjust default values for potential null bool
    this.adjustDefaultValues();

    // Update the table
    try {
      if (this.table instanceof StructType) {
        this.orderFields();
        this.table.name = this.editableTable.name;
        this.table.description = this.editableTable.description;
        this.table.isResource = this.editableTable.isResource;
        const fields: Record<string, Field> = {};
        for (const field of this.editableTable.fields) {
          if (field.generatedField.fieldId === '') {
            alert('Field ID cannot be empty');
            return;
          }
          if (field.generatedField.fieldEditor?.showIf === 'Select a field') {
            field.generatedField.fieldEditor.showIf = undefined;
            field.generatedField.fieldEditor.showIfValue = undefined;
          }
          fields[field.generatedField.fieldId] = await Field.deserialize(field.generatedField);
          fields[field.generatedField.fieldId].tags = field.tags;
          field.isNew = false;
          if (field.generatedField.fieldEditor && !field.generatedField.fieldEditor.showIf) {
            field.generatedField.fieldEditor.showIf = 'Select a field';
          }
        }
        this.table.fields = fields;

        // We manually save to possibly trigger a 409 error and force save
        await this.structTypeRepository.save(this.table);
        await this.addResourceTagToTable();
      } else if (this.table instanceof EnumType) {
        this.table.name = this.editableTable.name;
        this.table.description = this.editableTable.description;
        this.table.isResource = this.editableTable.isResource;
        this.table.options = this.editableTable.options.slice();

        // We manually save to possibly trigger a 409 error and force save
        await this.enumTypeRepository.save(this.table);
        await this.addResourceTagToTable();
      } else {
        this.table.name = this.editableTable.name;
        this.table.description = this.editableTable.description;
        this.table.options = this.editableTable.selectTypeOptions.slice();
      }
    } catch (error) {
      // If table is a structType, we give the user the option to delete all instances of the Struct in order to update it
      if (
        this.table instanceof StructType &&
        error instanceof HttpErrorResponse &&
        error.error &&
        error.error.status === 409 &&
        !error.error.message.includes('unique')
      ) {
        if (
          !(await firstValueFrom(
            this.confirmationModalService.confirm(
              `${error.error.error}: ${error.error.message}\n\nDo you want to use the force parameter and delete all values of this field?`,
            ),
          ))
        ) {
          return;
        }
        if (!(await firstValueFrom(this.confirmationModalService.confirm(`Are you sure you want to delete all values of this field?`)))) {
          return;
        }
        try {
          await this.structTypeRepository.save(this.table as StructType, true);
          this.orderFields();
          await this.addResourceTagToTable();
        } catch (e) {
          if (e instanceof HttpErrorResponse) {
            this.handleError(error);
            return;
          }
        }
      } else if (this.table instanceof EnumType && error instanceof HttpErrorResponse && error.error && error.error.status === 409) {
        if (
          !(await firstValueFrom(
            this.confirmationModalService.confirm(
              `${error.error.error}: ${error.error.message}\n\nDo you want to use the force parameter and delete the option anyway?`,
            ),
          ))
        ) {
          return;
        }
        try {
          await this.enumTypeRepository.save(this.table as EnumType, true);
          await this.addResourceTagToTable();
        } catch (e) {
          if (e instanceof HttpErrorResponse) {
            this.handleError(error);
            return;
          }
        }
      } else {
        this.handleError(error);
        return;
      }
    }

    this.router.navigate(this.navigationService.getUrlOfDataModelType(this.table.typeId, this.getTableType(this.table))).then();
    this.savePointer = this.tablePointer;
  }

  /**
   * Add an item to the table based on its type.
   */
  async addItem() {
    if (this.editableTable.type === 'StructType') {
      const newField = {
        fieldId: '',
        name: '',
        type: '',
        defaultValue: '',
        description: '',
        required: true,
        casOnly: false,
        deprecated: false,
        fieldEditor: {
          editorType: FieldEditorType.Default,
          hideInSeamlessInline: false,
          fieldId: '',
          position: this.editableTable.fields.length,
          showIf: 'Select a field',
          showResource: false,
          structId: this.editableTable.typeId,
          textEditorType: GeneratedFieldEditor.TextEditorTypeEnum.Area,
        },
        fieldValidations: [],
        tags: [],
      } as GeneratedField;
      this.editableTable.fields.push(await this.createEditableField(newField, [], true));
    } else if (this.editableTable.type === 'EnumType') {
      const newOption = '';
      this.editableTable.options.push(newOption);
    } else if (this.editableTable.type === 'SelectType') {
      const newOption = new SelectTypeOption({
        optionId: '',
        label: '',
      });
      this.editableTable.selectTypeOptions.push(newOption);
    }

    this.saveCurrentState();
  }

  /**
   * Remove an item from the table based on its type.
   * @param identifierToRemove The ID of the item to remove.
   */
  removeItem(identifierToRemove: string | SelectTypeOption) {
    if (this.editableTable.type === 'StructType' && typeof identifierToRemove === 'string') {
      this.editableTable.fields = this.editableTable.fields.filter((field) => field.generatedField.fieldId !== identifierToRemove);
      this.fieldsForShowIf = this.fieldsForShowIf.filter((f) => f.fieldId !== identifierToRemove);
      this.editableTable.fields.forEach((f) => {
        if (f.generatedField.fieldEditor?.showIf === identifierToRemove) {
          f.generatedField.fieldEditor.showIf = 'Select a field';
        }
      });
      this.updateFieldPositions();
    } else if (this.editableTable.type === 'EnumType') {
      this.editableTable.options = this.editableTable.options.filter((option) => option !== identifierToRemove);
    } else {
      this.editableTable.selectTypeOptions = this.editableTable.selectTypeOptions.filter(
        (option) => (option as SelectTypeOption).optionId !== identifierToRemove,
      );
    }

    this.saveCurrentState();
  }

  /**
   * Copies the current table to the clipboard.
   */
  async copyTable() {
    if (!this.table) {
      return;
    }

    if (this.hasUnsavedChanges()) {
      if (
        !confirm(
          'You have unsaved changes. Are you sure you want to copy the current table? This will copy the table version of your last save.',
        )
      ) {
        return;
      }
    }

    const serializedTable = [{ type: this.editableTable.type, data: await this.table.serialize() }];
    const tableJSON = JSON.stringify(serializedTable);

    navigator.clipboard
      .writeText(tableJSON)
      .then(() => {
        this.alertService.showAlert('Copied to clipboard successfully!', BootstrapClass.SUCCESS);
        console.log('JSON string copied to clipboard successfully!');
      })
      .catch((error) => {
        this.handleError(error);
      });
  }

  /**
   * Checks if a StructType exists based on a field type reference
   * todo: This function should also return true if the reference is an existing SelectType or EnumType
   * @param typeReference
   */
  isExistingStruct(typeReference: string) {
    const regex = /^(?:List<)?(\w+(Ref|Select))<(\w+)>>?$/;

    return (
      Object.keys(this.structTypes).includes(typeReference) ||
      Object.keys(this.structTypes).includes(
        typeReference.replace(regex, (match, typePart, refOrSelect, fieldPart) => {
          return fieldPart;
        }),
      )
    );
  }

  /**
   * Links the user to a different table
   * @param type The type to visit
   */
  visitTable(type: SelectTypeOption | string) {
    // If the current table is an EnumType, the link to navigate is easy
    if (this.table instanceof EnumType) {
      this.router.navigate(this.navigationService.getUrlOfDataModelType(type as string, 'StructType')).then();
    }

    // If the current table is a StructType, we first have to extract the typeId
    else if (this.table instanceof StructType && typeof type === 'string') {
      const regex = /^(?:List<)?(\w+(Ref|Select))<(\w+)>>?$/;

      const navigatePath = type
        .replace(regex, (match, typePart, refOrSelect, fieldPart) => {
          const replacedTypePart = typePart.endsWith('Ref') ? typePart.replace('Ref', 'Type') : `${typePart}Type`;
          return `${replacedTypePart}/${fieldPart}`;
        })
        .split('/');

      this.router.navigate(this.navigationService.getUrlOfDataModelType(navigatePath[1], navigatePath[0])).then();
    }

    this.openAccordionIndex = null;
  }

  /**
   * Updates all Field positions of the table.
   */
  updateFieldPositions() {
    if (this.editableTable.type === 'StructType') {
      // Set the position for each Field based on its current index
      for (const field of this.editableTable.fields) {
        const index: number = this.editableTable.fields.indexOf(field);
        if (field.generatedField.fieldEditor) {
          // Explicitly checking before access
          field.generatedField.fieldEditor.position = index;
        }
      }
    }
  }

  updateFieldId(field: GeneratedField, newFieldId: string) {
    const showIfField = this.fieldsForShowIf.find((f) => f.fieldId === field.fieldId && f.name === field.name);
    if (showIfField) {
      showIfField.fieldId = newFieldId;
      this.editableTable.fields.forEach((f) => {
        if (f.generatedField.fieldEditor?.showIf === field.fieldId) {
          f.generatedField.fieldEditor.showIf = newFieldId;
        }
      });
    }

    field.fieldId = newFieldId;
    this.saveCurrentState();
  }

  updateFieldName(field: GeneratedField, newFieldName: string) {
    const showIfField = this.fieldsForShowIf.find((f) => f.fieldId === field.fieldId && f.name === field.name);
    if (showIfField) {
      showIfField.name = newFieldName;
    }
    field.name = newFieldName;
    this.saveCurrentState();
  }

  async updateFieldType(field: SchemaTableField, newType: string) {
    field.generatedField.type = newType;
    this.filterTypes(field.generatedField.type);

    field.possibleFieldValidations = FieldValidation.fieldValidationsPerType[field.generatedField.type] ?? [];
    field.typeIsStructType = this.isExistingStruct(field.generatedField.type);
    if (
      (newType === 'bool' || newType.startsWith('Select<')) &&
      !this.fieldsForShowIf.find((f) => f.fieldId === field.generatedField.fieldId)
    ) {
      this.fieldsForShowIf.push({ fieldId: field.generatedField.fieldId, name: field.generatedField.name });
      this.saveCurrentState();
      return;
    }

    if (newType !== 'bool' && !(await FieldTypes.isSelectTypeValid(newType))) {
      this.fieldsForShowIf = this.fieldsForShowIf.filter((f) => f.name === 'Select a field' || f.fieldId !== field.generatedField.fieldId);
      this.editableTable.fields.forEach((f) => {
        if (f.generatedField.fieldEditor?.showIf === field.generatedField.fieldId) {
          f.generatedField.fieldEditor.showIf = undefined;
          f.generatedField.fieldEditor.showIfValue = undefined;
        }
      });
    }
  }

  async updateShowIf(field: SchemaTableField, value: string) {
    const fieldOnShowIf = this.editableTable.fields.find((f) => f.generatedField.fieldId === value);
    if (!fieldOnShowIf) {
      console.error(`Field with id ${value} not found`);
      return;
    }

    if (fieldOnShowIf.generatedField.type === 'bool') {
      field.showIfType = 'bool';
      field.generatedField.fieldEditor!.showIfValue = 'false';
    } else if (await FieldTypes.isSelectTypeValid(fieldOnShowIf.generatedField.type)) {
      field.showIfType = 'Select';
      const type = FieldTypes.getReferencedTypeId(fieldOnShowIf.generatedField.type);
      if (!type) {
        console.error(`Type ${fieldOnShowIf.generatedField.type} is not a valid select type, maybe its a list?`);
        return;
      }

      const selectType = this.selectTypes[type];
      field.generatedField.fieldEditor!.showIfValue = selectType.options[0].optionId;
      field.optionsForShowIfDropdown = selectType.options.slice();
    }

    this.saveCurrentState();
  }

  updateShowIfValue(field: GeneratedField, value: string) {
    field.fieldEditor!.showIfValue = value.toString();
    this.saveCurrentState();
  }

  updateDefaultValue(field: GeneratedField, newDefaultValue: boolean) {
    field.defaultValue = newDefaultValue.toString();
    this.saveCurrentState();
  }

  onAddFieldValidation(field: SchemaTableField, event: Event) {
    const selectElement = event.target as HTMLSelectElement;
    const selectedFieldValidationType = selectElement.value;
    if (!field.generatedField.fieldValidations) {
      field.generatedField.fieldValidations = [];
    }
    field.generatedField.fieldValidations.push({
      fieldId: field.generatedField.fieldId,
      validationType: selectedFieldValidationType as GeneratedFieldValidation.ValidationTypeEnum,
      validationValue: '',
    });
    field.possibleFieldValidations.splice(
      field.possibleFieldValidations.indexOf(selectedFieldValidationType as GeneratedFieldValidation.ValidationTypeEnum),
      1,
    );
    this.saveCurrentState();
  }

  onRemoveFieldValidation(field: SchemaTableField, validation: GeneratedFieldValidation) {
    field.generatedField.fieldValidations = field.generatedField.fieldValidations!.filter(
      (fieldValidation) => fieldValidation !== validation,
    );
    field.possibleFieldValidations.push(validation.validationType);
    this.saveCurrentState();
  }

  /**
   * Initializes FieldEditors for Fields that don't have a FieldEditor yet.
   */
  initEmptyFieldEditors() {
    if (this.editableTable.type === 'StructType') {
      for (const field of this.editableTable.fields) {
        const index = this.editableTable.fields.indexOf(field);
        if (!field.generatedField.fieldEditor || field.generatedField.fieldEditor.editorType === undefined) {
          field.generatedField.fieldEditor = {
            editorType: FieldEditorType.Default,
            hideInSeamlessInline: false,
            fieldId: field.generatedField.fieldId,
            position: index,
            showIf: undefined,
            showIfValue: undefined,
            showResource: false,
            structId: this.editableTable.typeId,
            scope: undefined,
            displayField: undefined,
            textEditorType: GeneratedFieldEditor.TextEditorTypeEnum.Area,
          };
          this.saveCurrentState();
        }
      }
    }
  }

  /**
   * Handles a CdkDragDrop event
   * @param event
   */
  drop(event: CdkDragDrop<string[]>) {
    if (this.editableTable.type === 'StructType') {
      moveItemInArray(this.editableTable.fields, event.previousIndex, event.currentIndex);
      this.updateFieldPositions();
      this.saveCurrentState();
    }
  }

  /**
   * Orders all Fields based on their position value
   */
  orderFields() {
    if (this.editableTable.type === 'StructType') {
      if (this.editableTable.fields.some((field) => field.generatedField.fieldEditor)) {
        this.editableTable.fields.sort((a, b) => {
          // Provide a default position value when fieldEditor is undefined
          const positionA = a.generatedField.fieldEditor?.position ?? Number.MAX_SAFE_INTEGER;
          const positionB = b.generatedField.fieldEditor?.position ?? Number.MAX_SAFE_INTEGER;
          return positionA - positionB;
        });
      }
    }
  }

  /**
   * Filters types based on user input
   * @param input
   */
  filterTypes(input: string) {
    this.filteredSchemaTypes = this.schemaTypes.filter((type: string) => type.toLowerCase().includes(input.toLowerCase()));
  }

  /**
   * Filters StructTypes based on user input
   * @param input
   */
  filterStructs(input: string) {
    return (this.filteredStructsTypes = Object.keys(this.structTypes)
      .filter((struct) => struct.toLowerCase().includes(input.toLowerCase()))
      .sort());
  }

  /**
   * Saves the current state of the table to the local version history
   */
  saveCurrentState() {
    // Timeout so save happens after model change
    setTimeout(() => {
      this.tableHistory = this.tableHistory.slice(0, this.tablePointer + 1);
      this.tableHistory.push(JSON.parse(JSON.stringify(this.editableTable)));
      this.tablePointer = this.tableHistory.length - 1;
    }, 0);
  }

  /**
   * Tracks accordion index
   * @param index
   */
  trackByFn(index: number): number {
    return index;
  }

  /**
   * Undoes last change
   */
  undo() {
    if (this.canUndo()) {
      this.tablePointer--;
      this.editableTable = JSON.parse(JSON.stringify(this.tableHistory[this.tablePointer]));
    }
  }

  /**
   * Redoes last change
   */
  redo() {
    if (this.canRedo()) {
      this.tablePointer++;
      this.editableTable = JSON.parse(JSON.stringify(this.tableHistory[this.tablePointer]));
    }
  }

  /**
   * Checks if user can Undo a change
   */
  canUndo() {
    return this.tablePointer > 0;
  }

  /**
   * Checks if user can Redo a change
   */
  canRedo() {
    return this.tablePointer < this.tableHistory.length - 1;
  }

  hasUnsavedChanges() {
    return this.savePointer !== this.tablePointer;
  }

  onTagSelected(_event: { tag: Tag; isAdded: boolean }, _fieldId: string) {
    this.saveCurrentState();
  }

  /**
   * Adjust default values for edge cases
   */
  adjustDefaultValues() {
    if (this.editableTable.type === 'StructType') {
      for (const field of this.editableTable.fields) {
        // Adjust default values of Lists
        if (field.generatedField.type.startsWith('List')) {
          field.generatedField.defaultValue = '[]';
        } else if (
          field.generatedField.type === 'Vector2' &&
          field.generatedField.defaultValue &&
          !Vector2.isValidString(field.generatedField.defaultValue)
        ) {
          field.generatedField.defaultValue = '0;0';
        } else if (
          field.generatedField.type === 'bool' &&
          field.generatedField.defaultValue !== 'true' &&
          field.generatedField.defaultValue !== 'false'
        ) {
          field.generatedField.defaultValue = 'false';
        } else if (field.generatedField.type === 'float' && field.generatedField.defaultValue?.startsWith('.')) {
          field.generatedField.defaultValue = '0' + field.generatedField.defaultValue;
        }
        // Adjust default values of non-required fields, Structs and StructRefs
        else if (
          !field.generatedField.required ||
          field.generatedField.type === 'ImageRef' ||
          field.generatedField.type === 'AudioRef' ||
          field.generatedField.type === 'VideoRef' ||
          field.generatedField.type === 'FileRef' ||
          field.generatedField.type.startsWith('Struct')
        ) {
          field.generatedField.defaultValue = undefined;
        }
      }
    }
  }

  /**
   * Returns an array of all fields with type StructRef
   */
  getFieldsWithTypeStruct() {
    if (this.table instanceof StructType) {
      return Object.values(this.table.fields).filter((field: Field) => field.type.startsWith('StructRef<'));
    }
    return [];
  }

  /**
   * Returns all fields of a subStruct
   * @param fieldId
   */
  async getFieldsFromSubStruct(fieldId: string) {
    if (this.table instanceof StructType) {
      const type = Object.values(this.table.fields).find((field) => field.fieldId === fieldId)?.type;
      if (!type) {
        this.fieldsFromSubStruct = [];
        return;
      }
      const regex = /Struct(?:Ref)?<([^>]+)>/;
      const match = type.match(regex);
      if (match && !type.startsWith('List')) {
        this.fieldsFromSubStruct = await this.structTypeRepository.get(match[1]).then((struct) => Object.values(struct.fields));
        return;
      }
    }
    this.fieldsFromSubStruct = [];
  }

  /**
   * Creates a fieldMigration entry
   * @param existingFieldId
   * @param newFieldId
   */
  migrateField(existingFieldId: string, newFieldId: string) {
    if (this.table instanceof StructType) {
      if (!this.table.fieldMigrations) {
        this.table.fieldMigrations = [];
      }
      this.table.fieldMigrations.push({
        existingFieldId: existingFieldId,
        newFieldId: newFieldId,
      });
      const existingField = Object.values(this.table.fields).find((field) => field.fieldId == existingFieldId);
      if (existingField) {
        existingField.name = newFieldId.charAt(0).toUpperCase() + newFieldId.slice(1);
      }
      this.saveCurrentState();
    }
  }

  /**
   * Creates a fieldMigration to subStruct entry
   * @param parentField
   * @param subField
   * @param existingField
   */
  async migrateFieldToSubStruct(parentField: Field, subField: Field, existingField: GeneratedField) {
    if (this.table instanceof StructType) {
      if (subField.type != existingField.type) {
        if (
          !(await firstValueFrom(
            this.confirmationModalService.confirm(
              `Subtype field (${subField.type}) does not match existing field (${existingField.type}).

Do you still want to continue?`,
            ),
          ))
        ) {
          return;
        }
      }
      if (!this.table.fieldMigrations) {
        this.table.fieldMigrations = [];
      }

      if (existingField.type) {
        this.table.fieldMigrations.push({
          existingFieldId: existingField.fieldId,
          newFieldId: `${parentField.fieldId}.${subField.fieldId}`,
        });
      }

      if (parentField !== existingField) {
        this.removeItem(existingField.fieldId);
      }
      this.saveCurrentState();
    }
  }

  /**
   * Opens a modal
   * @param content
   */
  openModal(content: TemplateRef<NgbModalRef>) {
    this.modalService.dismissAll('Closed before opening new modal');
    this.modalService.open(content, { ariaLabelledBy: 'upload-modal-title' }).result.then();
  }

  /**
   * Handles any error
   * @param error
   */
  handleError(error: unknown) {
    console.error('An error occurred:', error);

    // Handle HTTP errors
    if (error instanceof HttpErrorResponse) {
      // Server or connection error happened
      if (!navigator.onLine) {
        // Handle offline error
        alert('No Internet Connection\n\nPlease check your network connection.');
        this.alertService.showAlert('No Internet Connection', BootstrapClass.DANGER);
      } else {
        // Handle Http Error (error.status === 403, 404...)
        alert(`Server Error: ${error.status}\n\n${error.error.message}`);
        this.alertService.showAlert('Server Error', BootstrapClass.DANGER);
      }
    } else if (error instanceof TypeError) {
      // Handle client-side or network error
      alert('Error: A network error occurred\n\nPlease try again later.');
      this.alertService.showAlert('Error: A network error occurred', BootstrapClass.DANGER);
    } else if (error instanceof SyntaxError) {
      // Handle Syntax Errors
      alert(`Syntax Error: \n\n${error.message}`);
      this.alertService.showAlert('Syntax Error', BootstrapClass.DANGER);
    } else if (error instanceof Error) {
      // Handle generic error conditions
      alert(`Error\n\n${error.message}`);
      this.alertService.showAlert('Error', BootstrapClass.DANGER);
    } else {
      // Handle unknown errors
      alert('Unknown Error\n\nAn unknown error occurred. Please contact support.');
      this.alertService.showAlert('Unknown Error', BootstrapClass.DANGER);
    }
  }

  /**
   * Handles a keyboardEvent
   * @param event
   */
  @HostListener('window:keydown', ['$event']) handleKeyboardEvent(event: KeyboardEvent) {
    if (event.ctrlKey && event.key === 'z') {
      event.preventDefault();
      this.undo();
    } else if (event.ctrlKey && event.key === 'y') {
      event.preventDefault();
      this.redo();
    } else if (event.ctrlKey && event.key === 's') {
      event.preventDefault();
      this.updateTable().then();
    }
  }

  protected getTableType(table: StructType | EnumType | SelectType): 'StructType' | 'EnumType' | 'SelectType' {
    if (table instanceof StructType) return 'StructType';
    if (table instanceof EnumType) return 'EnumType';
    return 'SelectType';
  }

  private async createEditableField(field: GeneratedField, tags: Tag[], isNewField: boolean): Promise<SchemaTableField> {
    const editableField: SchemaTableField = {
      generatedField: field,
      showIfType: undefined,
      possibleFieldValidations: [],
      typeIsStructType: this.isExistingStruct(field.type),
      tags: tags.slice(),
      isNew: isNewField,
      optionsForShowIfDropdown: [],
    };

    if (field.fieldEditor?.showIf) {
      if (!(this.table as StructType).fields[field.fieldEditor.showIf]) {
        console.error(`Field with id ${field.fieldEditor.showIf} not found, but was used in the showIf`);
      } else {
        const typeOfFieldInShowIf = (this.table as StructType).fields[field.fieldEditor.showIf].type;
        if (typeOfFieldInShowIf === 'bool') {
          editableField.showIfType = 'bool';
        } else if (await FieldTypes.isSelectTypeValid(typeOfFieldInShowIf)) {
          editableField.showIfType = 'Select';
          const type = FieldTypes.getReferencedTypeId(typeOfFieldInShowIf);
          if (!type) {
            console.error(`Type ${typeOfFieldInShowIf} is not a valid select type, maybe its a list?`);
          }
          const selectType = this.selectTypes[type!];
          editableField.optionsForShowIfDropdown = selectType.options.slice();
        }
      }
    }

    if (FieldValidation.fieldValidationsPerType[field.type]) {
      editableField.possibleFieldValidations = FieldValidation.fieldValidationsPerType[field.type].filter((validation) => {
        return !field.fieldValidations || !field.fieldValidations.some((fieldValidation) => fieldValidation.validationType === validation);
      });
    }

    return editableField;
  }

  private async addResourceTagToTable() {
    if (!this.table || this.table instanceof SelectType) return;
    if (this.table.isResource && !this.table.tags.some((tag) => tag.uid === 'tag_defaultResourceTag')) {
      this.table.tags.push(await this.tagRepository.get('tag_defaultResourceTag'));
    } else if (!this.table.isResource && this.table.tags.some((tag) => tag.uid === 'tag_defaultResourceTag')) {
      this.table.tags.splice(
        this.table.tags.findIndex((tag) => tag.uid === 'tag_defaultResourceTag'),
        1,
      );
    }
  }
}
