export const environment = {
  production: false,
  authServerHost: 'https://api.electromagnetism.dev.gametailors.com/auth/',
  casHost: 'https://api.electromagnetism.dev.gametailors.com/cas',
  // TODO: Do not hardcode this
  dataPackage: 'dev_datapackage-824b700b-2198-4b9f-8e7b-b6d2a7acf605',
  defaultGame: 'electro',
  aspectRatio: { width: 1920, height: 1080 },
  playGameUrl: 'https://play.electromagnetism.dev.gametailors.com/',

  version_latestBuildNumber: '1379',
  version_latestCommitHash: '9d4b793d63b8f55ad00f90f61c9ebb6c2495c16a',
  version_latestDate: '28/03/2025 11:00',
};
