<div class="file-selector">
  <!-- detailed preview -->
  <app-file-detailed-preview [file]="selectedFileMeta" />

  <!-- buttons -->
  <div class="d-flex align-items-center">
    <button
      (click)="clear()"
      [disabled]="!selectedFileMeta"
      class="btn btn-no-space"
      ngbPopover="Clear selection. (Does not delete the {{ typeLabel }})"
      triggers="hover"
      type="button"
    >
      <span class="bi bi-x"></span>
    </button>
    <button
      (click)="openSelectFileModal(fileSelectorModal)"
      class="btn btn-no-space"
      id="select-file-button"
      ngbPopover="Select {{ typeLabel }}"
      triggers="hover"
      type="button"
    >
      <span class="bi bi-folder2-open"></span>
    </button>
    <app-file-input
      (updated)="select($event.file)"
      [accept]="accept"
      appearance="icon-button"
      label="Upload new {{ typeLabel }}"
      mode="create"
    />
    <app-file-input
      (updated)="select($event.file)"
      [accept]="accept"
      [disabled]="!selectedFileMeta"
      [fileToEdit]="selectedFileMeta"
      appearance="icon-button"
      label="Edit {{ typeLabel }}"
      mode="edit"
    />
    <app-tags
      (tagSelected)="selectTag()"
      *ngIf="selectedFileMeta"
      [allTags]="tags"
      [tagScope]="GeneratedTag.ScopeEnum.Instance"
      [tags]="selectedFileMeta.tags"
    ></app-tags>
  </div>
</div>

<!-- File selector modal -->
<ng-template #fileSelectorModal let-modal>
  <div class="modal-header" id="select-file-modal-header">
    <h5 class="modal-title" id="loadModalLabel">Select {{ typeLabel }}</h5>
    <button
      (click)="modal.dismiss('Cross click')"
      aria-label="Close"
      class="btn-close"
      id="select-file-modal-close-button"
      type="button"
    ></button>
  </div>

  <div class="modal-body">
    <ng-container>
      <div class="d-flex py-2 sticky-top bg-body">
        <input
          (ngModelChange)="searchDebounced($event)"
          [(ngModel)]="searchTerm"
          class="form-control"
          id="search-input"
          placeholder="Search"
          type="text"
        />

        <button (click)="this.searchTerm = ''; search()" *ngIf="searchTerm.length" class="btn btn-secondary ms-2" type="button">
          <span class="bi bi-x"></span>
        </button>
      </div>

      <div class="file-table-row file-table-header">
        <div></div>
        <div>Name</div>
        <div>Type</div>
        <div style="justify-self: right">Size</div>
        <div></div>
      </div>
      <div *ngIf="isLoading" class="text-center py-5" style="position: absolute; inset-inline: 0">
        <div class="spinner-border" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>

      <cdk-virtual-scroll-viewport (scrolledIndexChange)="clearPopovers()" class="file-table" itemSize="48" style="height: 50vh">
        <div
          (click)="select(file)"
          (keyup)="$event.key === 'Enter' && select(file)"
          *cdkVirtualFor="let file of visibleFiles; trackBy: trackByFileId"
          class="file-table-row file-table-item"
          role="button"
          style="height: 48px"
          tabindex="0"
        >
          <app-file-preview [file]="file" />
          <div style="word-break: break-all">
            <span *ngIf="file.note" class="bi bi-journal" container="body" ngbPopover="{{ file.note }}" triggers="hover"> </span>
            <app-file-name [name]="file.name" />
          </div>
          <div class="text-muted">
            {{ file.fileType.split('/')[1] }}
          </div>
          <div class="text-muted" style="text-align: right">
            {{ file.fileSize | formatBytes }}
          </div>
          <div style="display: grid; place-items: center">
            <button
              #menuTrigger="matMenuTrigger"
              (click)="$event.stopPropagation()"
              [matMenuTriggerFor]="menu"
              class="btn btn-no-space options-button"
              mat-icon-button
            >
              <i class="bi bi-three-dots"></i>
            </button>
            <mat-menu #menu="matMenu" matMenuContent>
              <div>
                <app-file-input
                  (updated)="select($event.file)"
                  [accept]="accept"
                  [fileToEdit]="file"
                  appearance="modal-button"
                  label="Edit"
                  mode="edit"
                />

                <button (click)="remove(file)" mat-menu-item>
                  <i class="bi-trash"></i>
                  <span> Delete</span>
                </button>
              </div>
            </mat-menu>
          </div>
        </div>
      </cdk-virtual-scroll-viewport>
    </ng-container>
  </div>
</ng-template>
